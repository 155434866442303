import React from 'react';
import { ArrayField, Datagrid, FunctionField, List, SingleFieldList, TextField } from 'react-admin';

const CommunicationsList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <TextField source="sender_id" />
      <TextField source="title" />
      <TextField source="message" />
      <ArrayField source="user_ids" label="Users">
        <SingleFieldList linkType={null}>
          <FunctionField render={(record) => <TextField record={{ name: record }} source="name" />} />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export default CommunicationsList;
