import React from 'react';
import { Datagrid, ImageField, List, NumberField, ReferenceField, TextField } from 'react-admin';

const AccountsList = () => (
  <List filter={{ role: 'all' }}>
    <Datagrid bulkActionButtons={null}>
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="type" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="phone" />
      <TextField source="max_qualification_level" />
      <ImageField source="imageURL" label="Image" />
      <TextField source="market_id" />
      <ReferenceField source="market_manager_id" reference="accounts" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="metrics.lifetime_completed_jobs" label="Completed Jobs" />
    </Datagrid>
  </List>
);

export default AccountsList;
