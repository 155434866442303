import React from 'react';
import { Datagrid, List, ReferenceField, TextField } from 'react-admin';

const MarketsList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="manager_id" reference="accounts" link={false}>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default MarketsList;
