import React from 'react';
import { Box, Typography } from '@mui/material';

const DatagridEmpty = () => (
  <Box position="absolute" display="flex" justifyContent="center" alignItems="center" top={0} right={0} bottom={0} left={0}>
    <Typography variant="h6" align="center">
      No records - please adjust search criteria and retry.
    </Typography>
  </Box>
);

export default DatagridEmpty;
