import React, { useContext, useEffect, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import { bool, func, shape, string } from 'prop-types';

import { Box, Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';

import { TwilioClientContext } from '@/providers/TwilioClient';

const SelectableConversation = ({ label, onClick, selected, unread, conversation, disabled }) => {
  const { identity } = useGetIdentity();
  const [unreadCount, setUnreadCount] = useState(0);
  const [twilioConversation, setTwilioConversation] = useState();

  const { client } = useContext(TwilioClientContext);

  const updateUnread = async () => {
    let newCount = await twilioConversation.getUnreadMessagesCount();
    if (newCount === null) {
      newCount = await twilioConversation.getMessagesCount();
    }
    setUnreadCount(newCount);
  };

  const messageAdded = async (newMessage) => {
    // Needed to check if current user sent last message
    if (newMessage?.author === identity?.id) {
      // Current user sent message override unread to 0
      setUnreadCount(0);
    } else {
      // Otherwise check normally
      updateUnread();
    }
  };

  useEffect(() => {
    if (twilioConversation) {
      updateUnread();
      twilioConversation.on('updated', messageAdded);
      twilioConversation.on('messageAdded', messageAdded);
    }
    return () => {
      twilioConversation?.removeListener('updated', messageAdded);
      twilioConversation?.removeListener('messageAdded', messageAdded);
    };
  }, [twilioConversation]);

  useEffect(() => {
    if (conversation && client) {
      (async () => {
        setTwilioConversation(await client.getConversationBySid(conversation.twilio_conversation_id));
      })();
    }
  }, [conversation, client]);

  return (
    <Box
      py={1}
      pl={unread ? 5 : 7}
      onClick={!disabled ? onClick : () => {}}
      title={disabled ? 'Disabled - Not in conversation' : ''}
      sx={{
        ...(disabled && { color: ({ palette }) => palette.text.secondary }),
        ...(!disabled && {
          '&:hover': {
            backgroundColor: (theme) => (selected ? theme.palette.neutral.dark : theme.palette.secondary.darkest),
            cursor: 'pointer',
          },
        }),
        backgroundColor: (theme) => (selected ? theme.palette.neutral.dark : 'none'),
      }}
      display="flex"
      alignItems="center"
    >
      {unreadCount > 0 && !selected && (
        <Box pr={1}>
          <Circle
            sx={{
              fontSize: '8px',
              color: (theme) => theme.palette.accent.main,
            }}
          />
        </Box>
      )}
      <Typography textTransform="capitalize">{label}</Typography>
    </Box>
  );
};

SelectableConversation.propTypes = {
  label: string.isRequired,
  onClick: func,
  selected: bool,
  unread: bool,
  conversation: shape({}),
  disabled: bool,
};

SelectableConversation.defaultProps = {
  onClick: () => {},
  selected: false,
  unread: false,
  conversation: null,
  disabled: false,
};

export default SelectableConversation;
