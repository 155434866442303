import React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import dataProvider from '@/api/dataProvider';

import MyLayout from '@/ReactAdmin/Layout';
import queryClient from '@/ReactAdmin/queryClient';
import store from '@/ReactAdmin/store';
import Login from '@/ReactAdmin/Login';
import Dashboard from '@/ReactAdmin/Dashboard';
import Comms from '@/ReactAdmin/CustomRoutes/Comms';
import CommsHub from '@/ReactAdmin/CustomRoutes/Comms/Hub';
import CommsJobs from '@/ReactAdmin/CustomRoutes/Comms/Jobs';

import AccountsList from '@/resources/accounts/List';
import CommunicationsCreate from '@/resources/communications/Create';
import CommunicationsList from '@/resources/communications/List';
import CommunicationsShow from '@/resources/communications/Show';
import ConversationsList from '@/resources/conversations/List';
import ConversationShow from '@/resources/conversations/Show';
import JobList from '@/resources/jobs/List';
import JobShow from '@/resources/jobs/Show';
import MarketsList from '@/resources/markets/List';

import theme from './theme';
import authProvider from './authProvider';

const App = () => (
  <Admin
    loginPage={Login}
    authProvider={authProvider}
    queryClient={queryClient}
    store={store}
    dataProvider={dataProvider()}
    layout={MyLayout}
    theme={theme}
    disableTelemetry
    dashboard={Dashboard}
    requireAuth
  >
    {(permissions) => (
      <>
        {permissions?.resources?.jobs?.read ? <Resource name="jobs" list={JobList} show={JobShow} /> : null}
        {permissions?.resources?.accounts?.read ? <Resource name="accounts" list={AccountsList} /> : null}
        {permissions?.resources?.markets?.read ? <Resource name="markets" list={MarketsList} /> : null}
        {permissions?.resources?.conversations?.read ? (
          <Resource name="conversations" list={ConversationsList} show={ConversationShow} />
        ) : null}
        {permissions?.resources?.communications?.read ? (
          <Resource
            name="communications"
            list={CommunicationsList}
            show={CommunicationsShow}
            create={permissions?.resources?.communications?.create ? CommunicationsCreate : null}
          />
        ) : null}
        {permissions?.apps?.commsTool?.view ? (
          <CustomRoutes>
            {/* Test Change */}
            <Route path="/comms" element={<Comms />} />
            {permissions?.apps?.commsTool?.hub ? <Route path="/comms/hub" element={<CommsHub />} /> : null}
            {permissions?.apps?.commsTool?.jobConversations ? <Route path="/comms/jobs" element={<CommsJobs />} /> : null}
          </CustomRoutes>
        ) : null}
      </>
    )}
  </Admin>
);

export default App;
