import palette from '@/theme/palette';

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.secondary.darker,
      boxShadow: 'none',
    },
  },
};
