import palette from '@/theme/palette';

export default {
  styleOverrides: {
    root: {
      '&.RaMenuItemLink-active': {
        background: palette.neutral.dark,
        color: palette.neutral.light,
      },
      '&.RaMenuItemLink-active:hover': {
        cursor: 'default',
      },
    },
  },
};
