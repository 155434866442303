import React from 'react';
import { ArrayField, ChipField, Datagrid, FunctionField, List, SingleFieldList, TextField } from 'react-admin';

const ConversationsList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="twilio_conversation_id" />
      <ChipField source="criteria.market_id" label="Market" />
      <ChipField source="criteria.order_id" label="Order" />
      <ArrayField source="criteria.users" label="Users">
        <SingleFieldList linkType={null}>
          <FunctionField render={(record) => <ChipField record={{ name: record }} source="name" />} />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="participants" label="Participants">
        <SingleFieldList linkType={null}>
          <FunctionField render={({ user_id: userId }) => <ChipField record={{ name: userId }} source="name" />} />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export default ConversationsList;
