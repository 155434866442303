import React, { useContext } from 'react';
import { ShowBase } from 'react-admin';
import { string } from 'prop-types';

import TwilioClientProvider, { TwilioClientContext } from '@/providers/TwilioClient';

import Conversation from './Conversation';

const ConversationShow = ({ conversationId }) => {
  const { client } = useContext(TwilioClientContext);

  const showProps = {
    ...(conversationId && { id: conversationId }),
  };

  const showContent = (
    <ShowBase resource="conversations" {...showProps}>
      <Conversation />
    </ShowBase>
  );

  return client ? showContent : <TwilioClientProvider>{showContent}</TwilioClientProvider>;
};

ConversationShow.propTypes = {
  conversationId: string,
};

ConversationShow.defaultProps = {
  conversationId: null,
};

export default ConversationShow;
