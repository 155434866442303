import { useEffect, useState } from 'react';
import { useGetIdentity, useGetList, usePermissions } from 'react-admin';
import onlyUnique from './onlyUnique';

// Hook dependent on React Admin identity - If no identity then hook will always return null
const useMarketConversations = () => {
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();

  const [mappedMarketConversations, setMappedMarketConversations] = useState(null);

  const { data: marketConversations, isLoading: isMarketConversationsLoading } = useGetList(
    'conversations',
    {
      pagination: { page: 1, perPage: 999 },
      filter: {
        name: ['All', 'Captains', 'Drivers'],
        user_id_all: identity?.id,
      },
    },
    {
      enabled: Boolean(permissions?.resources?.conversations?.read) && Boolean(identity),
    },
  );

  const { data: markets, isLoading: isMarketsLoading } = useGetList(
    'markets',
    {
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'name', order: 'ASC' },
      filter: {
        id: (marketConversations?.map((c) => c.criteria?.market_id) ?? []).filter(onlyUnique),
      },
    },
    {
      enabled: Boolean(permissions?.resources?.markets?.read) && Boolean(identity) && Boolean(marketConversations),
    },
  );

  useEffect(() => {
    // Because this is a stacked useEffect relying on two RA data calls - make sure all in good state before loading
    // Markets array must exist
    // Must not be currently loading market conversation
    // Must be ready to load market conversations (set in markets useEffect)
    // Market conversations data from RA must exist
    if (!isMarketsLoading && markets && !isMarketConversationsLoading && marketConversations) {
      const emptyMarketConversations = markets.map((market) => ({ marketId: market.id, marketName: market.name }));
      const newMappedConversations = emptyMarketConversations?.map((mappedMarket) => ({
        ...mappedMarket,
        conversations: {
          all: marketConversations.find(
            ({ name, criteria: { market_id: marketId } }) => marketId === mappedMarket.marketId && name === 'All',
          ),
          captains: marketConversations.find(
            ({ name, criteria: { market_id: marketId } }) => marketId === mappedMarket.marketId && name === 'Captains',
          ),
          drivers: marketConversations.find(
            ({ name, criteria: { market_id: marketId } }) => marketId === mappedMarket.marketId && name === 'Drivers',
          ),
        },
      }));

      setMappedMarketConversations(newMappedConversations);
    }
  }, [markets, marketConversations, isMarketConversationsLoading, isMarketsLoading]);

  if (!identity) {
    // If no identity found - we don't know who user is to determine market conversations
    return { data: null };
  }

  return { data: mappedMarketConversations };
};

export default useMarketConversations;
