import React, { useEffect, useState } from 'react';
import { useAuthProvider, useGetIdentity, useGetList, useNotify, useRedirect } from 'react-admin';
import { Autocomplete, TextField } from '@mui/material';

import postData from '@/utils/postData';
import useDirectMessageConversations from '@/utils/useDirectMessageConversations';

const CreateDmLookup = () => {
  const { identity } = useGetIdentity();
  const { getAccessToken } = useAuthProvider();

  const { data: userMarkets } = useGetList(
    'markets',
    {
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'name', order: 'ASC' },
      filter: {
        manager_id: identity?.id,
      },
    },
    {
      enabled: Boolean(identity),
    },
  );
  const { data: directMessageConversations, refetch: refetchDirectMessageConversations } = useDirectMessageConversations();

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [noOptionsText, setNoOptionsText] = useState('Start typing to find a Bellhop');
  const [, setConversationCriteria] = useState({});
  const [loadingNewDm, setLoadingNewDm] = useState(false);

  const notify = useNotify();
  const redirect = useRedirect();

  const { data: accountsData, isLoading } = useGetList(
    'accounts',
    {
      filter: {
        type: 'Bellhop',
        q: inputValue,
        role: 'all',
        ...(userMarkets?.length > 0 && { market_id: userMarkets?.map((market) => market.id) }),
      },
    },
    {
      enabled: inputValue !== '' && Boolean(userMarkets),
    },
  );

  const createNewDm = async () => {
    const token = await getAccessToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/conversations/participants`;
    const conversationData = {
      users: [value.id, identity?.id],
    };
    setLoadingNewDm(true);
    postData(url, conversationData, token)
      .then(async (response) => {
        if (!response.ok) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ status: response.status, result: await response.json() });
        }
        return response.json();
      })
      .then(({ id }) => {
        // Initiate refetch so if user comes back from conversation page, the new data exists for accurate UI display
        refetchDirectMessageConversations();
        setLoadingNewDm(false);
        // Redirect to conversation just created
        redirect(`/comms/hub?selectedConversation=${id}`);
      })
      .catch((error) => {
        setLoadingNewDm(false);
        setValue('');
        setInputValue('');
        const baseError = 'An error occurred joining the conversation';
        if (error.status) {
          notify(`${baseError} - ${error.status} - ${error.result?.message ?? error.result}`);
        } else {
          notify(`${baseError} - Unknown Error - ${error}`);
        }
      });
  };

  useEffect(() => {
    // Runs when value is selected from Autocomplete
    // First checks if conversation exists between these two users
    // If found, switch to Hub with conversation preselected
    // If no conversation is found, new one is created, then go to hub with conversation preselected
    if (value) {
      setConversationCriteria({
        users: [value.id, identity?.id],
      });
      const existingDm = directMessageConversations.find((c) => c.recipientName === value.name);
      if (existingDm) {
        redirect(`/comms/hub?selectedConversation=${existingDm.id}`);
      } else {
        createNewDm({ users: [value.id, identity?.id] });
      }
    }
  }, [value]);

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      setNoOptionsText('Start typing to find a Bellhop');
    }
  }, [inputValue]);

  useEffect(() => {
    setOptions(accountsData || []);
    if (accountsData?.length === 0) {
      setNoOptionsText('No Bellhops found');
    }
  }, [accountsData]);

  return !loadingNewDm ? (
    <Autocomplete
      id="test"
      loading={isLoading}
      options={options}
      noOptionsText={noOptionsText}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      // eslint-disable-next-line camelcase
      getOptionLabel={({ first_name, last_name }) => `${first_name} ${last_name}`}
      renderInput={(params) => <TextField {...params} label="To" fullWidth />}
    />
  ) : (
    <>Loading</>
  );
};

export default CreateDmLookup;
