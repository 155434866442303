import { useQuery } from 'react-query';
import authProvider from '@/authProvider';

const useTwilioAccountToken = ({ accountId, enabled }) => {
  const fetchToken = async () => {
    const apiToken = await authProvider.getAccessToken();
    const options = {
      headers: new Headers({
        Authorization: `Bearer ${apiToken}`,
      }),
    };
    const query = `${process.env.REACT_APP_API_URL}/v1/conversations/token`;
    return fetch(query, options)
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          throw new Error(`Error occurred while fetching Twilio token - ${data?.message}`);
        }
        return response.json();
      })
      .then((data) => data);
  };

  const { data, refetch, error, isLoading } = useQuery(['twilioToken', accountId], fetchToken, {
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60, // Every hour get new token
    enabled: enabled && Boolean(accountId),
    retry: false,
  });

  return [data?.token, refetch, { error, isLoading }];
};

export default useTwilioAccountToken;
