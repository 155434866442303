import React from 'react';

import { Box } from '@mui/material';
import ModuleBar from './ModuleBar';

const Menu = () => (
  <Box height="100%" display="flex">
    <ModuleBar />
  </Box>
);

export default Menu;
