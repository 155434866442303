import React, { useContext } from 'react';
import { func, string } from 'prop-types';

import { Box, IconButton, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import { TwilioClientContext } from '@/providers/TwilioClient';
import useDirectMessageConversations from '@/utils/useDirectMessageConversations';
import useMarketConversations from '@/utils/useMarketConversations';

import SelectableConversation from './SelectableConversation';

const HubConversations = ({ selectedConversation, setSelectedConversation }) => {
  const { isCurrentUserParticipant } = useContext(TwilioClientContext);

  const { data: marketConversations } = useMarketConversations();
  const { data: directMessageConversations } = useDirectMessageConversations();

  return (
    <Box
      width={300}
      flexShrink={0}
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.darker,
        color: (theme) => theme.palette.neutral.lightest,
        borderTop: (theme) => `2px solid ${theme.palette.neutral.dark}`,
        borderRight: (theme) => `2px solid ${theme.palette.neutral.dark}`,
        overflowY: 'auto',
      }}
    >
      <Box>
        <Box m={2} mb={1}>
          <Typography variant="h5">Markets</Typography>
        </Box>
        {marketConversations?.length > 0 ? (
          marketConversations?.map(({ marketId, marketName, conversations }) => {
            const isUserAllParticipant = isCurrentUserParticipant(conversations.all);
            const isUserCaptainsParticipant = isCurrentUserParticipant(conversations.captains);
            const isUserDriversParticipant = isCurrentUserParticipant(conversations.drivers);
            const allConversation = conversations.all && (
              <SelectableConversation
                key={`${marketId}-all`}
                selected={selectedConversation === conversations.all.id}
                label="All"
                disabled={!isUserAllParticipant}
                onClick={() => {
                  setSelectedConversation(conversations.all.id);
                }}
                conversation={conversations.all}
              />
            );
            const captainsConversation = conversations.captains && (
              <SelectableConversation
                key={`${marketId}-captains`}
                selected={selectedConversation === conversations.captains.id}
                label="Captains"
                disabled={!isUserCaptainsParticipant}
                onClick={() => {
                  setSelectedConversation(conversations.captains.id);
                }}
                conversation={conversations.captains}
              />
            );
            const driversConversation = conversations.drivers && (
              <SelectableConversation
                key={`${marketId}-drivers`}
                selected={selectedConversation === conversations.drivers.id}
                label="Drivers"
                disabled={!isUserDriversParticipant}
                onClick={() => {
                  setSelectedConversation(conversations.drivers.id);
                }}
                conversation={conversations.drivers}
              />
            );
            const availableConversations = [allConversation, captainsConversation, driversConversation].filter((i) => !!i);
            return availableConversations.length > 0 ? (
              <Box key={marketId}>
                <Box py={1} px={4}>
                  <Typography variant="h6">{marketName}</Typography>
                </Box>
                <Box>{availableConversations}</Box>
              </Box>
            ) : (
              <Box key={marketId}>
                <Box py={1} px={4}>
                  <Typography variant="h6">{marketName}</Typography>
                </Box>
                <Typography variant="body2" ml={6}>
                  No conversations found for market
                </Typography>
              </Box>
            );
          })
        ) : (
          <Box ml={4}>No markets found for user</Box>
        )}
      </Box>
      {directMessageConversations?.length > 0 && (
        <Box>
          <Box m={2} mb={1} display="flex" justifyContent="space-between">
            <Typography variant="h5">Directs</Typography>
            <IconButton
              onClick={() => setSelectedConversation('')}
              sx={{
                padding: 0,
                color: ({ palette }) => palette.neutral.lighter,
                '&:hover': {
                  color: ({ palette }) => palette.neutral.medium,
                },
              }}
            >
              <Add />
            </IconButton>
          </Box>
          {directMessageConversations?.map((c) =>
            c?.recipientName ? (
              <SelectableConversation
                key={c.id}
                selected={selectedConversation === c.id}
                label={c.recipientName}
                onClick={() => {
                  setSelectedConversation(c.id);
                }}
                conversation={c}
              />
            ) : null,
          )}
        </Box>
      )}
    </Box>
  );
};

HubConversations.propTypes = {
  selectedConversation: string,
  setSelectedConversation: func.isRequired,
};

HubConversations.defaultProps = {
  selectedConversation: null,
};

export default HubConversations;
