import React, { useContext, useEffect, useState } from 'react';
import { Button, CreateButton, Link, Title, useGetIdentity, useGetList, usePermissions } from 'react-admin';
import { stringify } from 'query-string';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Typography } from '@mui/material';

import TwilioClientProvider, { TwilioClientContext } from '@/providers/TwilioClient';
import CreateDmLookup from '@/shared/CreateDmLookup';
import useDirectMessageConversations from '@/utils/useDirectMessageConversations';
import useMarketConversations from '@/utils/useMarketConversations';

import RecentComms from './RecentComms';

dayjs.extend(utc);
dayjs.extend(timezone);

const Comms = () => {
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();
  const { clientReady, getUnreadMessageCountFromConversations } = useContext(TwilioClientContext);
  const { data: marketConversations } = useMarketConversations();
  const { data: directMessageConversations } = useDirectMessageConversations();

  const { data: userMarkets } = useGetList(
    'markets',
    {
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'name', order: 'ASC' },
      filter: {
        manager_id: identity?.id,
      },
    },
    {
      enabled: Boolean(identity),
    },
  );

  const [unreadMarketMessagesCount, setUnreadMarketMessagesCount] = useState();
  const [unreadDirectMessagesCount, setUnreadDirectMessagesCount] = useState();

  const refreshUnreadMarketMessageCounts = () => {
    // Get all UUIDs of market conversations - flatten and then filter non null entries
    const conversationIds = marketConversations
      .map(({ conversations: { all, captains, drivers } = {} }) => [all?.id, captains?.id, drivers?.id])
      .flat()
      .filter((c) => !!c);
    getUnreadMessageCountFromConversations(conversationIds).then((unreadCount) => setUnreadMarketMessagesCount(unreadCount));
  };

  const refreshUnreadDirectMessageCounts = () => {
    getUnreadMessageCountFromConversations(directMessageConversations.map((c) => c.id)).then((unreadCount) =>
      setUnreadDirectMessagesCount(unreadCount),
    );
  };

  useEffect(() => {
    if (marketConversations?.length > 0 && clientReady) {
      refreshUnreadMarketMessageCounts();
    }
  }, [marketConversations, clientReady]);

  useEffect(() => {
    if (directMessageConversations?.length > 0 && clientReady) {
      refreshUnreadDirectMessageCounts();
    }
  }, [directMessageConversations, clientReady]);

  const today = dayjs().tz('America/New_York');
  const todayStartOfDay = today.hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss');
  const todayEndOfDay = today.hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss');
  const todayFilters = {
    start_date_time_lte: todayEndOfDay,
    end_date_time_gte: todayStartOfDay,
  };

  const defaultJobsView = {
    sort: 'id',
    order: 'ASC',
    page: 1,
    perPage: 25,
  };

  return (
    <Box display="flex" height="calc(100vh - 48px)" width="100%" p={2}>
      <Title title="Comms Dashboard" />
      <Grid container spacing={2}>
        {permissions?.apps?.commsTool?.jobConversations ? (
          <Grid item xs={5}>
            <Card>
              <CardHeader title="Jobs" />
              <Divider />
              <CardContent>
                {userMarkets?.length === 0 ? (
                  <Link
                    to={`/comms/jobs?${stringify({
                      ...defaultJobsView,
                      displayedFilters: JSON.stringify({
                        start_date_time_lte: true,
                        end_date_time_gte: true,
                        status: true,
                      }),
                      filter: JSON.stringify({
                        ...todayFilters,
                        status: 'OPEN',
                      }),
                    })}`}
                  >
                    <Typography variant="h6">In-flight Jobs Today</Typography>
                  </Link>
                ) : (
                  <Typography variant="h6">In-flight Jobs Today</Typography>
                )}
                {userMarkets?.map(({ id, name }, index, { length }) => (
                  <Box key={id}>
                    <Box>
                      <Link
                        to={`/comms/jobs?${stringify({
                          ...defaultJobsView,
                          displayedFilters: JSON.stringify({
                            start_date_time_lte: true,
                            end_date_time_gte: true,
                            market_id: true,
                            status: true,
                          }),
                          filter: JSON.stringify({
                            ...todayFilters,
                            market_id: id,
                            status: 'OPEN',
                          }),
                        })}`}
                      >
                        {name}
                      </Link>
                    </Box>
                    {index !== length - 1 && <Divider />}
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        {permissions?.apps?.commsTool?.hub ? (
          <Grid item xs={3}>
            <Card>
              <CardHeader title="Hub" />
              <Divider />
              <CardContent>
                {unreadMarketMessagesCount !== undefined && unreadDirectMessagesCount !== undefined ? (
                  <Box mb={2}>
                    <Typography>
                      {unreadMarketMessagesCount} Unread Market message
                      {(unreadMarketMessagesCount === 0 || unreadMarketMessagesCount > 1) && 's'}
                    </Typography>
                    <Typography>
                      {unreadDirectMessagesCount} Unread Direct message
                      {(unreadDirectMessagesCount === 0 || unreadDirectMessagesCount > 1) && 's'}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="center" py={0.5} mb={2}>
                    <CircularProgress />
                  </Box>
                )}
                <Link to="/comms/hub">
                  <Button fullWidth variant="contained" label="Go to Hub" size="large" />
                </Link>
              </CardContent>
              {permissions?.apps?.commsTool?.createDm ? (
                <>
                  <Divider />
                  <CardContent>
                    <Typography variant="h6">New DM</Typography>
                    <CreateDmLookup />
                  </CardContent>
                </>
              ) : null}
            </Card>
          </Grid>
        ) : null}
        {permissions?.resources?.communications?.read ? (
          <Grid item xs={4}>
            <Card>
              <CardHeader title="Communications" />
              <Divider />
              {permissions?.apps?.commsTool?.createComms && (
                <>
                  <CardContent>
                    <Box p={2}>
                      <CreateButton
                        resource="communications"
                        label="Create Comm"
                        size="large"
                        fullWidth
                        variant="contained"
                      />
                    </Box>
                  </CardContent>
                  <Divider />
                </>
              )}
              <CardContent>
                <Typography variant="h6">Recent Comms</Typography>
                <Box pt={2}>
                  <RecentComms />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

const TwilioComms = () => (
  <TwilioClientProvider>
    <Comms />
  </TwilioClientProvider>
);

export default TwilioComms;
