import React, { useEffect, useState } from 'react';
import { bool, func, shape } from 'prop-types';
import { useGetIdentity, usePermissions, Confirm } from 'react-admin';

import { Box, IconButton, Typography } from '@mui/material';
import { AccountCircle, ExpandLess, ExpandMore, Delete, Cancel } from '@mui/icons-material';

import UnreadMarker from '../UnreadMarker';

const ConversationMessage = ({ message, addUnreadMarker, setLastReadMessage, refreshConversation }) => {
  const { identity } = useGetIdentity();
  const [friendlyName, setFriendlyName] = useState(null);
  const [messageMedia, setMessageMedia] = useState([]);
  const [mediaExpanded, setMediaExpanded] = useState(false);
  const sentByCurrentUser = message.author === identity?.id;

  const { permissions } = usePermissions();
  const canDelete = permissions?.resources?.conversation_message?.delete;
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const handleDeleteConfirmClick = () => setDeleteConfirmOpen(true);
  const handleDeleteConfirmDialogClose = () => setDeleteConfirmOpen(false);
  const handleDeleteConfirm = () => {
    message.remove();
    refreshConversation();
    setDeleteConfirmOpen(false);
  };

  const loadMessageMedia = async () => {
    const mediaImages = await Promise.all(
      message.attachedMedia.map(async (media) => ({
        sid: media.sid,
        filename: media.filename,
        url: await media.getContentTemporaryUrl(),
      })),
    );

    setMessageMedia(mediaImages);
  };

  useEffect(() => {
    if (message) {
      message
        .getParticipant()
        .then((p) => p.getUser())
        .then((u) => setFriendlyName(u.friendlyName));
    }
  }, [message]);

  return (
    message && (
      <>
        {addUnreadMarker && <UnreadMarker />}
        <Box
          px={4}
          py={2}
          onClick={() => {
            setLastReadMessage(message.index);
          }}
          display="flex"
        >
          <Box display="flex" px={2} pt={2.5}>
            <AccountCircle fontSize="large" />
          </Box>
          <Box flex={1} mr={2}>
            <Box display="flex">
              <Typography variant="body2">{friendlyName}</Typography>
              <Typography variant="body2" ml={2}>
                {message.dateCreated.toLocaleString()}
              </Typography>
            </Box>
            <Box display="flex">
              <Box
                px={2}
                py={1}
                whiteSpace="pre-wrap"
                sx={{
                  background: sentByCurrentUser ? '#6994f4' : '#e1eaf1',
                  color: sentByCurrentUser ? '#fff' : '#333',
                  borderRadius: 2,
                }}
              >
                {message.body}
              </Box>
            </Box>
            {message.attachedMedia?.length > 0 && (
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <Typography variant="body2">{`${message.attachedMedia.length} file${
                    message.attachedMedia.length > 1 ? 's' : ''
                  }`}</Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      loadMessageMedia(message.attachedMedia);
                      setMediaExpanded(!mediaExpanded);
                    }}
                  >
                    {mediaExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                  </IconButton>
                </Box>
                {mediaExpanded && (
                  <Box>
                    {messageMedia?.map(({ sid, filename, url }) => (
                      <Box key={sid} display="flex" maxWidth="50vw" sx={{ '& > img': { maxWidth: '100%' } }}>
                        <img src={url} alt={filename} />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {canDelete ? (
            <Box>
              <IconButton size="small" onClick={handleDeleteConfirmClick}>
                <Delete />
              </IconButton>
              <Confirm
                isOpen={deleteConfirmOpen}
                title="Delete Message"
                content="Are you sure you want to delete this message?"
                onConfirm={handleDeleteConfirm}
                onClose={handleDeleteConfirmDialogClose}
                CancelIcon={Cancel}
              />
            </Box>
          ) : null}
        </Box>
      </>
    )
  );
};

ConversationMessage.propTypes = {
  message: shape({}).isRequired,
  addUnreadMarker: bool,
  setLastReadMessage: func.isRequired,
  refreshConversation: func.isRequired,
};

ConversationMessage.defaultProps = {
  addUnreadMarker: false,
};

export default ConversationMessage;
