import React from 'react';
import { DateField, Show, SimpleShowLayout, TextField } from 'react-admin';

const JobShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="start_date_time" />
      <DateField source="end_date_time" />
      <DateField source="completed_date_time" />
      <TextField source="start_market_id" />
      <TextField source="end_market_id" />
      <TextField source="product_id" />
      <TextField source="order_confirmation_code" />
      <TextField source="order_id" />
      <TextField source="order_sfid" />
      <TextField source="status" />
    </SimpleShowLayout>
  </Show>
);

export default JobShow;
