import { defaultTheme } from 'react-admin';
import { merge } from 'lodash';

import mixins from './mixins';
import typography from './typography';
import palette from './palette';
import components from './components';
import reactAdmin from './reactAdmin';

const theme = merge({}, defaultTheme, {
  mixins,
  typography,
  palette,
  components,
  ...reactAdmin,
});

export default theme;
