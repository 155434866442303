import React, { useEffect, useState } from 'react';
import {
  BooleanInput,
  CreateBase,
  Datagrid,
  Form,
  ListBase,
  ListToolbar,
  LoadingIndicator,
  ReferenceArrayInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  Title,
  useGetIdentity,
  useListContext,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useLocation } from 'react-router';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { node, string } from 'prop-types';
import _ from 'lodash';

import { Box, Button, Card, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';

import DatagridEmpty from '@/shared/DatagridEmpty';

const HOPPER_UUID = '00000000-0000-0000-0000-000000000000';
const defaultFilterValues = { role: '', market_id: [], completed_jobs_gte: '', completed_jobs_lte: '' };

const ReceipientList = () => {
  const { isFetching } = useListContext();

  return isFetching ? (
    <LoadingIndicator />
  ) : (
    <Datagrid
      // we don't have any bulk actions but we need the checkboxes to select for the create form, false removes the checkboes entirely, so use empty fragment to remove default delete button
      // eslint-disable-next-line react/jsx-no-useless-fragment
      bulkActionButtons={<></>}
      empty={<DatagridEmpty />}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        '& .RaDatagrid-tableWrapper': {
          width: '100%',
          height: '100%',
          overflowY: 'auto',
        },
      }}
    >
      <TextField source="name" />
      <TextField source="market_id" />
      <TextField source="metrics.lifetime_completed_jobs" label="Job Count" />
    </Datagrid>
  );
};

const defaultValue = {
  title: '',
  message: '',
  urgent: false,
  sender_id: HOPPER_UUID,
  user_ids: [],
  source: 'COMMS_HUB',
};

const CommunicationsRoot = ({ children }) => (
  <Card
    sx={{
      mx: 3,
      p: 3,
      flex: '1 1 auto',
    }}
  >
    {children}
  </Card>
);

CommunicationsRoot.propTypes = {
  children: node.isRequired,
};

const CommunicationsCreateForm = () => {
  const largeHeight = useMediaQuery('(min-height: 1079px)');
  const { filterValues, total: accountsTotal, onUnselectItems } = useListContext();
  const { reset } = useFormContext();
  const redirect = useRedirect();

  const { identity } = useGetIdentity();
  const location = useLocation();
  const record = location?.state?.record;
  const CHARACTER_LIMIT = 5000;

  const notify = useNotify();
  const { selectedIds } = useListContext();

  const [values, setValues] = useState(record || defaultValue);

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  useEffect(() => {
    setValues({ ...values, user_ids: selectedIds });
  }, [selectedIds]);

  const metadata = {
    markets: filterValues?.market_id,
    role: filterValues?.role,
    ...(filterValues?.completed_jobs_gte && {
      completed_jobs_gte: filterValues?.completed_jobs_gte,
    }),
    ...(filterValues?.completed_jobs_lte && {
      completed_jobs_lte: filterValues?.completed_jobs_lte,
    }),
    possibleTotal: accountsTotal,
  };

  const transform = (data) => ({
    ...data,
    user_ids: selectedIds,
    metadata: JSON.stringify(metadata),
    created_by: identity?.id,
  });

  const sendDisabled = !(selectedIds?.length > 0 && values?.title !== '' && values?.message !== '' && values?.type !== '');

  return (
    <Box px={5} py={largeHeight ? 4 : 2} sx={{ background: '#eee', borderTop: '1px solid #333' }}>
      <Box>
        <TextInput source="title" label="Subject" variant="outlined" fullWidth required onChange={handleChange('title')} />
      </Box>
      <Box>
        <TextInput
          source="message"
          variant="outlined"
          multiline
          fullWidth
          required
          rows={largeHeight ? 8 : 4}
          inputProps={{
            maxLength: CHARACTER_LIMIT,
          }}
          helperText={`${CHARACTER_LIMIT - (values?.message?.length ?? 0)} Characters left`}
          onChange={handleChange('message')}
          sx={{
            '& .MuiFormHelperText-root': {
              textAlign: 'right',
            },
          }}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" mr={2}>
          <SelectInput
            source="sender_id"
            label="From"
            emptyValue={HOPPER_UUID}
            emptyText="Hopper"
            choices={[{ id: `${identity?.id}`, name: 'Current User' }]}
            onChange={handleChange('sender_id')}
            helperText={false}
          />
        </Box>
        <Box display="flex" mr={2}>
          <SelectInput
            source="type"
            choices={[
              { id: 'Market Update', name: 'Market Update' },
              { id: 'Launch', name: 'Launch' },
              { id: 'New Feature', name: 'New Feature' },
              { id: 'Outage Update', name: 'Outage Update' },
              { id: 'Announcement', name: 'Announcement' },
            ]}
            onChange={handleChange('type')}
            helperText={false}
          />
        </Box>

        <Box display="flex">
          <BooleanInput source="urgent" onChange={handleChange('urgent')} color="error" helperText={false} />
        </Box>
        <Box ml="auto" display="flex">
          <SaveButton
            disabled={sendDisabled}
            type="button"
            label="Send"
            icon={null}
            mutationOptions={{
              onSuccess: ({ id }) => {
                notify('Communication Sent', { type: 'success' });
                reset(); // Resets message fields, subject, message, type, urgent
                onUnselectItems(); // Deselects all rows in table
                redirect('show', 'communications', id);
              },
              onError: (error) => {
                notify(`An error occurred sending the communication - Unknown Error - ${error}`);
              },
            }}
            transform={transform}
          />
        </Box>
      </Box>
    </Box>
  );
};

const PostFilterForm = () => {
  const { filterValues, setFilters, onUnselectItems } = useListContext();

  const methods = useForm({
    defaultValues: filterValues ?? defaultFilterValues,
  });

  const {
    handleSubmit,
    reset,
    getValues,
    control,
    watch,
    formState: { isDirty: formIsDirty },
  } = methods;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values, Object.keys(values));
      reset(getValues());
      // onUnselectItems deselects all currently selected items in the Datagrid when new filter is submitted
      onUnselectItems();
    }
  };

  const resetFilters = () => {
    setFilters({}, []);
    reset(defaultFilterValues, { keepValues: false });
    // onUnselectItems deselects all currently selected items in the Datagrid when filter is cleared
    onUnselectItems();
  };

  useEffect(() => {
    if (_.isEmpty(filterValues)) {
      reset(defaultFilterValues, { keepValues: false });
    }
  }, [filterValues]);

  const role = watch('role');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box display="flex" justifyContent="center" mt={2} mb={1}>
            <Controller
              name="role"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup
                  color="secondary"
                  value={value}
                  onChange={onChange}
                  exclusive
                  aria-label="Role"
                  sx={{
                    '& .MuiToggleButton-root.Mui-selected': {
                      color: ({ palette }) => palette.secondary.darker,
                      backgroundColor: 'rgba(105, 148, 244, 0.25)',
                    },
                  }}
                >
                  <ToggleButton value="all">All</ToggleButton>
                  <ToggleButton value="captain">Captain</ToggleButton>
                  <ToggleButton value="lead">Lead</ToggleButton>
                  <ToggleButton value="pro">Pro</ToggleButton>
                  <ToggleButton value="recruit">Recruit</ToggleButton>
                  <ToggleButton value="driver">Driver</ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Box>
          <Box display="flex" alignItems="flex-end" mb={1} mx={2}>
            <Box component="span" mr={2}>
              <ReferenceArrayInput
                source="market_id"
                reference="markets"
                perPage={999}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <SelectArrayInput optionText="name" />
              </ReferenceArrayInput>
            </Box>
            <Box component="span" mr={2}>
              <SelectInput
                label=">= Jobs"
                source="completed_jobs_gte"
                choices={[
                  { id: 1, name: '1' },
                  { id: 10, name: '10' },
                  { id: 25, name: '25' },
                  { id: 50, name: '50' },
                ]}
              />
            </Box>
            <Box component="span" mr={2}>
              <SelectInput
                label="<= Jobs"
                source="completed_jobs_lte"
                choices={[
                  { id: 1, name: '0' },
                  { id: 10, name: '10' },
                  { id: 25, name: '25' },
                  { id: 50, name: '50' },
                ]}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={2} mx={3}>
            <Box>
              <Button variant="contained" onClick={resetFilters} color="neutral">
                Reset
              </Button>
            </Box>
            <Box>
              <Button variant="contained" color="primary" type="submit" disabled={!role || !formIsDirty}>
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

const ListActions = () => (
  <Box width="100%" sx={{ borderBottom: '1px solid #333' }}>
    <PostFilterForm />
  </Box>
);

const ReceipientListBase = ({ children, title, ...props }) => {
  const largeHeight = useMediaQuery('(min-height: 1079px)');
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ListBase {...props}>
        <Title title={title} />
        <ListToolbar actions={<ListActions />} />
        <Card component={Box} m={2} mt={largeHeight ? 10 : 8} flex={1} position="relative" sx={{ overflow: 'inherit' }}>
          {/* Inherit overflow needed to display bulk actions toolbar on top of list filters */}
          {children}
        </Card>
        <Box mt="auto">
          <Form defaultValues={{ sender_id: HOPPER_UUID }}>
            <CommunicationsCreateForm />
          </Form>
        </Box>
      </ListBase>
    </Box>
  );
};

ReceipientListBase.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
};

const CommunicationsCreate = (props) => {
  const location = useLocation();

  const record = location?.state?.record;

  const permanentFilters = {
    limit: 9999,
    type: 'Bellhop',
    status: 'Active',
  };

  return (
    <CreateBase record={record || defaultValue} {...props}>
      <Title title="Create Communication" />
      <ReceipientListBase resource="accounts" filter={permanentFilters} title=" ">
        <ReceipientList />
      </ReceipientListBase>
    </CreateBase>
  );
};

export default CommunicationsCreate;
