import React from 'react';
import { LoadingIndicator, SidebarToggleButton, UserMenu } from 'react-admin';

import { AppBar, Box, Typography } from '@mui/material';

import VerticalDivider from '../../shared/VerticalDivider';

const MyAppBar = () => (
  // <RaAppBar {...props} />
  <AppBar
    sx={{
      backgroundColor: ({ palette }) => palette.secondary.darker,
      color: ({ palette }) => palette.neutral.lightest,
      boxShadow: 'none',
    }}
  >
    <Box minHeight={48} display="flex">
      <Box mx={0.75}>
        <SidebarToggleButton />
      </Box>
      <Box display="flex" alignItems="center" py={1}>
        <Box mx={2} height={32} width={100} display="flex">
          <img src="/svgs/LogoDesktop.svg" alt="Bellhop Logo" width="100%" />
        </Box>
        <VerticalDivider />
        <Typography variant="h6" mx={2}>
          HQ Admin
        </Typography>
        <VerticalDivider />
        <Typography mx={2} variant="h6" id="react-admin-title" />
      </Box>
      <Box ml="auto" mr={1} display="flex">
        <LoadingIndicator />
        <UserMenu />
      </Box>
    </Box>
  </AppBar>
);

export default MyAppBar;
