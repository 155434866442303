import React, { useContext, useEffect, useState } from 'react';
import { useGetIdentity, useGetList, useNotify, useShowContext } from 'react-admin';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';

import { Box, Button, Icon, IconButton, TextField, Typography } from '@mui/material';
import { AddPhotoAlternate, ArrowBack, HighlightOff, Send } from '@mui/icons-material';

import { TwilioClientContext } from '@/providers/TwilioClient';

import fileToDataUri from '@/utils/file/fileToDataUri';
import TWILIO_FRIENDLY_STATUS from '@/utils/twilio';

import ConversationMessages from '../ConversationMessages';

const ConversationHeader = () => {
  const navigate = useNavigate();

  const {
    record: { id: conversationId, twilio_conversation_id: twilioConversationId, criteria: { order_id: orderId } = {} } = {},
  } = useShowContext();
  const { client, clientStatus, reinitClient } = useContext(TwilioClientContext);

  const { data: jobsData } = useGetList(
    'jobs',
    {
      filter: {
        order_id: orderId,
        status: ['OPEN', 'COMPLETE', 'CANCELLED'],
      },
    },
    {
      enabled: Boolean(orderId),
    },
  );

  const allUnread = () => {
    client.getConversationBySid(twilioConversationId).then((c) => c.setAllMessagesUnread());
  };

  const dates = jobsData?.map((j) => dayjs(j.start_date_time));
  const earliestDate = dates?.length > 0 ? dates?.reduce((a, b) => (a < b ? a : b)) : null;

  return (
    <Box
      sx={{
        background: '#ddd',
      }}
    >
      <Box p={1} sx={{ backgroundColor: ({ palette }) => palette.neutral.lighter }} display="flex">
        <Box ml={1} mr={2}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack
              sx={{
                fontSize: 32,
                color: ({ palette }) => palette.primary.main,
              }}
            />
          </IconButton>
        </Box>
        {Boolean(orderId) && (
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{`#${jobsData?.[0]?.order_confirmation_code}`}</Typography>
            <Typography variant="h5" component={Box} mx={2}>
              |
            </Typography>
            <Typography variant="h6">{`${earliestDate?.format('MM/DD/YYYY - HH:mm A')}`}</Typography>
          </Box>
        )}
      </Box>
      {process.env.NODE_ENV === 'development' && (
        <Box sx={{ borderTop: '1px solid #333', borderBottom: '1px solid #333' }} pt={3}>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            Dev Information
          </Typography>
          <Box display="flex" justifyContent="space-between" p={3}>
            <Box>
              <div>Convo ID: {conversationId}</div>
              <div>Twilio Convo ID: {twilioConversationId}</div>
              <div>Twilio Status: {TWILIO_FRIENDLY_STATUS[clientStatus]}</div>
            </Box>
            <Box display="flex" flexDirection="column">
              <Button variant="contained" onClick={allUnread}>
                All Unread
              </Button>
              <Button variant="contained" onClick={reinitClient}>
                Reset Twilio
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const Conversation = () => {
  const { identity } = useGetIdentity();
  const { record: { twilio_conversation_id: conversationSid } = {} } = useShowContext();
  const { client } = useContext(TwilioClientContext);
  const notify = useNotify();

  const [conversation, setConversation] = useState(null);
  const [draftMessage, setDraftMessage] = useState('');
  const [draftFiles, setDraftFiles] = useState([]);
  const [draftFilesTotalSize, setDraftFilesTotalSize] = useState(0);
  const disableMediaAttachment = true;

  useEffect(() => {
    console.log('Conversation - Client prop updated');
    const loadConversation = () => {
      setConversation(null);
      console.log(`Conversation - Twilio Client valid - Loading conversation ${conversationSid}`);
      client.getConversationBySid(conversationSid).then((c) => setConversation(c));
    };
    if (client && conversationSid) {
      loadConversation();
    }
  }, [client, conversationSid]);

  const conversationUpdated = () => {
    console.log('Twilio conversation data updated - Updating message and unread count');
  };

  const conversationMessageAdded = async (newMessage) => {
    // This should only fire for the actively loaded conversation
    console.log('Twilio conversation event - New message');
    const { identity: messageIdentity, friendlyName } = await newMessage.getParticipant().then((p) => p.getUser());
    if (messageIdentity !== identity?.id) {
      notify(`${friendlyName} sent a message!`);
    }
  };

  useEffect(() => {
    if (conversation) {
      console.log('Twilio conversation updated - Adding listeners and setting initial counts');
      conversation.on('updated', conversationUpdated);
      conversation.on('messageAdded', conversationMessageAdded);
    }
    return () => {
      conversation?.removeListener('updated', conversationUpdated);
      conversation?.removeListener('messageAdded', conversationMessageAdded);
    };
  }, [conversation]);

  const testSend = async () => {
    // TODO: Trigger loading indicator, the send function can take a little bit while files are uploaded
    const draftMessageBuilder = conversation.prepareMessage();
    draftMessageBuilder.setBody(draftMessage);

    await Promise.all(
      draftFiles.map(async ({ filename, media }) => {
        const file = await fetch(media);
        const fileBlob = await file.blob();
        return draftMessageBuilder.addMedia({ filename, media: fileBlob, contentType: file.headers.get('Content-Type') });
      }),
    );

    draftMessageBuilder
      .build()
      .send()
      .then(() => {
        conversation.setAllMessagesRead();
        setDraftMessage('');
        setDraftFiles([]);
      })
      .catch((e) => notify(`An error occurred sending your message - please try again later. ${e}`, { type: 'error' }));
  };

  const handleDraftMessageChange = (event) => {
    setDraftMessage(event.target.value);
  };

  const testKeyDown = (event) => {
    const { shiftKey, key } = event;
    if (!shiftKey && key === 'Enter') {
      event.preventDefault();
      testSend();
    }
  };

  const handleFileUpload = async (event) => {
    const { target } = event;
    const { files: eventFiles } = target;

    if (eventFiles.length + draftFiles.length > 10) {
      notify('Max of 10 attachments per message', { type: 'warning' });
      target.value = '';
      return;
    }

    const originalFiles = [];
    let totalSize = 0;

    for (let i = 0; i < eventFiles.length; i += 1) {
      // files from event are not iterable push into standard array
      originalFiles.push(eventFiles[i]);
      totalSize += eventFiles[i].size;
    }

    if (totalSize + draftFilesTotalSize > 1024 * 1024 * 100) {
      // Ensure total file size of existing draft files plus new are less than 100 MB
      notify('Max total attachment size of 100 MB', { type: 'warning' });
      target.value = '';
      return;
    }

    const files = await Promise.all(
      originalFiles.map(async (file) => {
        const media = await fileToDataUri(file)
          .then((data) => ({ data }))
          .catch((error) => ({ data: null, error }));
        if (media.data) {
          return {
            id: crypto.randomUUID(),
            contentType: file.type,
            filename: file.name,
            size: file.size,
            media: media.data,
          };
        }
        notify(`An error occurred with ${file.name} - Please try again later ${media.error ?? 'Unknown Error'}`, {
          type: 'error',
        });
        return null;
      }),
    );

    setDraftFiles((prevDraftFiles) => [...prevDraftFiles, ...files.filter((file) => !!file)]);
    setDraftFilesTotalSize((prevTotal) => prevTotal + totalSize);
    target.value = '';
  };

  const removeDraftFile = (id) => {
    const fileToRemove = draftFiles.find((file) => file.id === id);
    setDraftFiles((prevDraftFiles) => prevDraftFiles.filter((draftFile) => draftFile.id !== id));
    setDraftFilesTotalSize((prevTotal) => prevTotal - fileToRemove.size);
  };

  const messageInputProps = {
    InputProps: {
      endAdornment: (
        <Icon
          component="label"
          sx={{
            color: 'gray',
            '&:hover': {
              color: 'black',
              cursor: 'pointer',
            },
          }}
        >
          <AddPhotoAlternate />
          <input type="file" accept="image/*" hidden onChange={handleFileUpload} multiple />
        </Icon>
      ),
    },
  };

  return (
    <>
      <ConversationHeader />
      <Box flex={1} display="flex" flexDirection="column">
        <Box flex={1} position="relative">
          {conversation && <ConversationMessages conversation={conversation} />}
        </Box>
        <Box
          sx={{
            background: '#ddd',
          }}
          display="flex"
          flexDirection="column"
          px={2}
          pt={1}
        >
          {draftFiles?.length > 0 && (
            <Box display="flex">
              {draftFiles.map(({ id, size, filename, media, contentType }) => (
                <Box
                  width={100}
                  height={100}
                  m={1}
                  key={id}
                  onClick={() => {
                    removeDraftFile(id);
                  }}
                  sx={{
                    width: 100,
                    height: 100,
                    borderRadius: 4,
                    overflow: 'hidden',
                    backgroundColor: ({ palette }) => palette.neutral.lighter,
                    border: '2px solid grey',
                    p: 0.5,
                    position: 'relative',
                    '&:hover .filePreviewOverlay': {
                      backgroundColor: 'rgba(35,49,82,.5)',
                      display: 'flex',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Box
                    width="100%"
                    height="100%"
                    className="inner"
                    sx={{
                      '& > img': {
                        objectFit: 'cover',
                        borderRadius: 4,
                      },
                    }}
                  >
                    <img width="100%" height="100%" alt={filename} src={media} data-size={size} data-type={contentType} />
                  </Box>
                  <Box
                    title="Remove"
                    position="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    className="filePreviewOverlay"
                    display="none"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <HighlightOff
                      sx={{
                        fontSize: 50,
                        color: ({ palette }) => palette.neutral.main,
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          <Box display="flex" flex={1} alignItems="center">
            <Box flex={1}>
              <TextField
                variant="outlined"
                multiline
                value={draftMessage}
                onChange={handleDraftMessageChange}
                sx={{
                  background: 'white',
                  marginBottom: 1,
                  '& .MuiOutlinedInput-root': {
                    padding: '10px 14px',
                  },
                }}
                fullWidth
                autoComplete="off"
                onKeyDown={testKeyDown}
                {...(disableMediaAttachment ? {} : messageInputProps)}
              />
            </Box>
            <Box ml={2}>
              <IconButton
                disabled={!(draftMessage !== '' || draftFiles.length > 0)}
                onClick={testSend}
                sx={{
                  color: ({ palette }) => palette.primary.dark,
                  '&:disabled': {
                    color: ({ palette }) => palette.neutral.medium,
                  },
                  '&:hover': {
                    color: ({ palette }) => palette.primary.main,
                  },
                }}
              >
                <Send
                  sx={{
                    fontSize: 28,
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Conversation;
