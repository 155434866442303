import React from 'react';
import { Link, Title, usePermissions } from 'react-admin';

import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ChatOutlined } from '@mui/icons-material';

const Dashboard = () => {
  const { permissions, isLoading: isPermissionsLoading } = usePermissions();

  return (
    <>
      <Title title="Dashboard" />
      <Box width="100%" flex={1} p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Dashboard" sx={{ textAlign: 'center' }} />
            </Card>
          </Grid>
          {!isPermissionsLoading && permissions && (
            <>
              {permissions.apps?.commsTool?.view && (
                <Grid item xs={6}>
                  <Card>
                    <CardHeader title="Comms" sx={{ textAlign: 'center' }} />
                    <Divider />
                    <CardContent component={Box} display="flex" justifyContent="center">
                      <Link to="/comms">
                        <IconButton>
                          <ChatOutlined
                            sx={{
                              fontSize: 64,
                            }}
                          />
                        </IconButton>
                      </Link>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {Object.keys(permissions.resources).length === 0 && (
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography pt={2} textAlign="center" variant="h6">
                        You do not currently have access to any modules
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
