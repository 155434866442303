import { Auth0Client } from '@auth0/auth0-spa-js';
import authConfig from './authConfig';

const audience = process.env.REACT_APP_API_URL;

const clientOptions = {
  audience,
};

const auth0 = new Auth0Client({
  domain: authConfig.domain,
  client_id: authConfig.clientID,
  ...clientOptions,
});

export default {
  // called when the user attempts to log in
  login: ({ code, state, location, targetUrl }) => {
    if (!(code && state)) {
      return auth0
        .loginWithRedirect({
          redirect_uri: `${window.location.origin}/login`,
          ...clientOptions,
          ...(targetUrl && {
            appState: {
              targetUrl,
            },
          }),
        })
        .then(() => ({ redirectTo: false }));
    }

    return auth0
      .handleRedirectCallback(`${location.pathname}${location.search !== '' ? `?${location.search}` : ''}`)
      .then((res) => {
        const { appState: { targetUrl: redirectTarget } = {} } = res;
        return auth0
          .getIdTokenClaims()
          .then(({ user_permissions: userPermissions }) =>
            Promise.resolve(
              userPermissions.reduce((prev, permission) => {
                const [action, resource] = permission.split(':');
                return {
                  ...prev,
                  [resource]: {
                    ...(prev[resource] ?? {}),
                    [action]: true,
                  },
                };
              }, {}),
            ),
          )
          .then((permissions) => {
            const appPermissions = { apps: {} };
            appPermissions.apps.commsTool = {
              view: permissions.conversations?.read || permissions.communications?.read,
              createComms: permissions.communications?.create && permissions.accounts?.read && permissions.markets?.read,
              hub: permissions.conversations?.read && permissions.accounts?.read && permissions.markets?.read,
              createDm: permissions.conversations?.create && permissions.accounts?.read && permissions.markets?.read,
              jobConversations:
                permissions.jobs?.read &&
                permissions.markets?.read &&
                permissions.conversations?.read &&
                permissions.conversations?.create &&
                permissions.conversations?.update,
            };
            appPermissions.resources = permissions;
            localStorage.setItem('permissions', JSON.stringify(appPermissions));
            return Promise.resolve({ redirectTo: redirectTarget ?? '/' });
          });
      });
  },
  // called when the user clicks on the logout button
  logout: () =>
    auth0.isAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated) {
        // need to check for this as react-admin calls logout in case checkAuth failed
        localStorage.removeItem('permissions');
        return auth0.logout({
          returnTo: window.location.origin,
        });
      }
      return Promise.resolve();
    }),
  // called when the API returns an error
  checkError: ({ status, body: { message } }) => {
    if (status === 401 || status === 403) {
      // Required to use React Admin reject object, not error type
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ redirectTo: '/', message, logoutUser: false });
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () =>
    auth0.isAuthenticated().then(async (isAuthenticated) => {
      if (isAuthenticated) {
        return Promise.resolve();
      }
      return auth0.getTokenSilently();
    }),
  // get the user's profile
  getIdentity: () =>
    // React Admin needs fullName and avatar set in identity for user menu
    // Added id stripped from subject for API use
    Promise.resolve(auth0.getUser()).then((user) => ({
      ...user,
      id: process.env.REACT_APP_ACT_AS_USER ?? user?.sub.replace(/^auth0\|/, ''),
      fullName: user.name,
      avatar: user.picture,
    })),
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const perms = JSON.parse(localStorage.getItem('permissions'));
    return perms ? Promise.resolve(perms) : Promise.reject();
  },
  getAccessToken: () => auth0.getTokenSilently(),
};
