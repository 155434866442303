import React from 'react';

import { Box, Typography } from '@mui/material';
import { ChatOutlined, HomeOutlined, Storage } from '@mui/icons-material';
import { MenuItemLink, usePermissions, useSidebarState } from 'react-admin';
import _ from 'lodash';

const ModuleBar = () => {
  const [sidebarOpen, setSidebarOpen] = useSidebarState();
  const { permissions } = usePermissions();

  return (
    <Box
      width={sidebarOpen ? 240 : 60}
      zIndex={100}
      sx={{
        background: (theme) => theme.palette.secondary.darker,
        borderTop: (theme) => `2px solid ${theme.palette.neutral.dark}`,
        borderRight: (theme) => `2px solid ${theme.palette.neutral.dark}`,
        transitionProperty: 'width, align-items',
        transitionDuration: '195ms',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
        transitionDelay: '0ms',
      }}
    >
      <Box height="100%" p={1} display="flex" flexDirection="column" alignItems="flex-start" flexShrink={0}>
        <Box width="100%">
          {permissions && permissions.apps?.commsTool?.view && (
            <MenuItemLink
              to="/comms"
              primaryText={sidebarOpen ? 'Comms' : ''}
              leftIcon={<ChatOutlined sx={{ fontSize: 36 }} />}
              sx={{
                padding: 0.5,
              }}
            />
          )}
        </Box>
        <Box width="100%" mt="auto">
          <>
            {!_.isEmpty(permissions?.resources) ? (
              <Box>
                {sidebarOpen ? (
                  <>
                    <Box p={0.5} mb={1} display="flex" alignItems="center">
                      <Storage sx={{ fontSize: 36, color: ({ palette }) => palette.neutral.light }} />
                      <Typography pl={1}>Resources</Typography>
                    </Box>
                    <Box pl={4}>
                      {permissions?.resources?.jobs?.read ? <MenuItemLink to="jobs" primaryText="Comms Jobs" /> : null}
                      {permissions?.resources?.accounts?.read ? <MenuItemLink to="accounts" primaryText="Accounts" /> : null}
                      {permissions?.resources?.markets?.read ? <MenuItemLink to="markets" primaryText="Markets" /> : null}
                      {permissions?.resources?.conversations?.read ? (
                        <MenuItemLink to="conversations" primaryText="Conversations" />
                      ) : null}
                      {permissions?.resources?.communications?.read ? (
                        <MenuItemLink to="communications" primaryText="Communications" />
                      ) : null}
                    </Box>
                  </>
                ) : (
                  <Box
                    p={0.5}
                    mb={1}
                    display="flex"
                    sx={{
                      borderRadius: 2,
                      '&:hover': {
                        cursor: 'pointer',
                        background: ({ palette }) => palette.secondary.dark,
                      },
                    }}
                    onClick={() => setSidebarOpen(true)}
                  >
                    <Storage
                      sx={{
                        fontSize: 36,
                        color: (theme) => theme.palette.neutral.light,
                      }}
                    />
                  </Box>
                )}
              </Box>
            ) : null}
            <MenuItemLink
              to="/"
              primaryText={sidebarOpen ? 'Dashboard' : ''}
              leftIcon={<HomeOutlined sx={{ fontSize: 36 }} />}
              sx={{
                padding: 0.5,
              }}
            />
          </>
        </Box>
      </Box>
    </Box>
  );
};

ModuleBar.propTypes = {};

export default ModuleBar;
