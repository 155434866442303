import React from 'react';
import { Divider } from '@mui/material';

const VerticalDivider = () => (
  <Divider
    orientation="vertical"
    variant="middle"
    flexItem
    sx={{
      '&.MuiDivider-root': {
        backgroundColor: 'neutral.lightest',
        margin: 0,
      },
    }}
  />
);

export default VerticalDivider;
