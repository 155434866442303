import React, { createContext, useEffect, useMemo, useState } from 'react';
import { node } from 'prop-types';
import { Client as ConversationsClient } from '@twilio/conversations';
import { useGetIdentity, useNotify, usePermissions } from 'react-admin';

import TWILIO_FRIENDLY_STATUS from '@/utils/twilio';
import useTwilioAccountToken from '@/utils/useTwilioAccountToken';

const initialState = {};

export const TwilioClientContext = createContext({ initialState });

const TwilioClientProvider = ({ children }) => {
  const notify = useNotify();
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();
  const [token, refetchTwilioToken, { error: twilioTokenError }] = useTwilioAccountToken({
    accountId: identity?.id,
    enabled: Boolean(permissions?.resources?.conversations?.read),
  });

  const [client, setClient] = useState();
  const [clientReady, setClientReady] = useState(false);
  const [clientStatus, setClientStatus] = useState();
  const [subscribedConversations, setSubscribedConversations] = useState();

  const initClient = () => {
    if (!client) {
      console.log('Twilio client init called - Creating new Client');
      setClient(new ConversationsClient(token));
    } else {
      console.warn('Twilio client init called - Client already exists');
    }
  };

  const shutdownClient = () => {
    console.log('Twilio client shutdown');
    client.removeAllListeners();
    client.shutdown();
    setClientReady(false);
    setClient(null);
  };

  const reinitClient = () => {
    shutdownClient();
    refetchTwilioToken();
  };

  const loadSubscribedConversations = () => {
    client.getSubscribedConversations().then((conversations) => setSubscribedConversations(conversations.items));
  };

  const clientConnectionStateChanged = (state) => {
    console.log(`Twilio client status updated - New Status ${TWILIO_FRIENDLY_STATUS[state]}`);
    setClientStatus(state);
    if (state === 'connected') {
      loadSubscribedConversations();
    } else if (state === 'disconnected') {
      setClientReady(false);
    }
  };

  const clientMessageAdded = () => {
    console.log('Twilio client event - New message');
    // This could be used for inactive conversations - Believe this would fire for all conversations, not just the actively viewed one
  };

  const clientTokenAboutToExpire = () => {
    console.warn('Twilio token about to expire - Refetching New Token');
    refetchTwilioToken();
  };

  const isCurrentUserParticipant = (conversation) =>
    Boolean(conversation?.participants.find((p) => p.user_id === identity?.id));

  useEffect(() => {
    if (twilioTokenError) {
      notify(twilioTokenError?.message, { type: 'error' });
    }
  }, [twilioTokenError]);

  useEffect(() => {
    if (token) {
      if (client) {
        console.log('New token retrieved - Twilio client exists - Updating token');
        client.updateToken(token);
      } else {
        console.log('New token retrieved - Twilio client does not exist - Creating client');
        initClient();
      }
    }
  }, [token]);

  // Takes array of conversation UUIDs - returns total unread count from all conversations
  const getUnreadMessageCountFromConversations = async (conversationIds) => {
    const filteredConversations = subscribedConversations.filter((c) => conversationIds.includes(c.uniqueName));

    if (filteredConversations?.length === 0) {
      return Promise.resolve(0);
    }

    // For each conversation we get unread message count
    // If null - no messages read - return number of all messages
    // If not null - return unread message count
    const promises = filteredConversations.map((c) =>
      c
        .getUnreadMessagesCount()
        .then((unreadCount) => (unreadCount === null ? c.getMessagesCount() : Promise.resolve(unreadCount))),
    );

    // Wait for all promises then return the reduction sum of all unread counts
    const unreadCounts = await Promise.all(promises);
    return unreadCounts.reduce((a, b) => a + b);
  };

  useEffect(() => {
    if (subscribedConversations && clientStatus === 'connected') {
      setClientReady(true);
    }
  }, [subscribedConversations]);

  useEffect(() => {
    if (client) {
      console.log('Twilio client updated - Adding listeners');
      client.on('connectionStateChanged', clientConnectionStateChanged);
      client.on('messageAdded', clientMessageAdded);
      client.on('tokenAboutToExpire', clientTokenAboutToExpire);
    }
    return () => {
      // Required cleanup function to remove events to prevent duplication
      client?.removeAllListeners();
    };
  }, [client]);

  const value = useMemo(
    () => ({
      client,
      clientReady,
      clientStatus,
      reinitClient,
      shutdownClient,
      isCurrentUserParticipant,
      getUnreadMessageCountFromConversations,
    }),
    [client, clientReady, subscribedConversations],
  );

  return <TwilioClientContext.Provider value={value}>{children}</TwilioClientContext.Provider>;
};

TwilioClientProvider.propTypes = {
  children: node.isRequired,
};

export default TwilioClientProvider;
