import { BulkActionsToolbarClasses } from 'react-admin';

import palette from '@/theme/palette';

export default {
  styleOverrides: {
    root: {
      [`& .${BulkActionsToolbarClasses.toolbar}`]: {
        backgroundColor: palette.neutral.lighter,
        transform: 'translateY(-64px)',
        height: 64,
      },
      [`& .${BulkActionsToolbarClasses.collapsed}`]: {
        transform: 'translateY(0)',
        height: 0,
      },
      [`& .${BulkActionsToolbarClasses.title}`]: {
        alignItems: 'center',
        color: palette.neutral.darkest,
      },
    },
  },
};
