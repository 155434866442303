import palette from '@/theme/palette';

export default {
  styleOverrides: {
    root: {
      '& .RaNotification-success': {
        backgroundColor: palette.success.main,
      },
    },
  },
};
