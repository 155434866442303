import React, { useEffect, useState } from 'react';
import { LoadingIndicator, ShowBase, useGetMany, useGetOne, useNotify, useShowContext } from 'react-admin';
import dayjs from 'dayjs';

import { Box, Card, Chip, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

const HOPPER_UUID = '00000000-0000-0000-0000-000000000000';

const Communication = () => {
  const {
    record: {
      created_by: createdBy,
      created_date: createdDate,
      title,
      message,
      metadata,
      sender_id: senderId,
      type,
      user_ids: userIds,
    } = {},
    isLoading: recordIsLoading,
  } = useShowContext();
  const notify = useNotify();

  const [parsedMetadata, setParsedMetadata] = useState({});

  const { markets, role, possibleTotal, completed_jobs_gte: jobsGte, completed_jobs_lte: jobsLte } = parsedMetadata;

  const { data: createdByAccount } = useGetOne(
    'accounts',
    { id: createdBy },
    { enabled: !recordIsLoading && createdBy && createdBy !== HOPPER_UUID },
  );
  const { data: senderAccount } = useGetOne(
    'accounts',
    { id: senderId },
    { enabled: !recordIsLoading && senderId && senderId !== HOPPER_UUID },
  );
  const { data: marketsData } = useGetMany('markets', { ids: markets }, { enabled: markets?.length > 0 });

  let roleDisplay = '';

  switch (role) {
    case 'all':
      roleDisplay = 'All Pros';
      break;
    default:
      roleDisplay = role;
      break;
  }

  let accountsText = `${userIds?.length} of ${possibleTotal}`;

  if (!possibleTotal) {
    accountsText = `${userIds?.length}`;
  }

  useEffect(() => {
    if (metadata) {
      let newMetadata = {};
      try {
        newMetadata = JSON.parse(metadata);
      } catch (error) {
        notify(`An error occurred parsing metadata - ${error}`);
      }
      setParsedMetadata(newMetadata);
    }
  }, [metadata]);

  return (
    <Box minWidth={750} maxWidth={1000} mx="auto" pt={3}>
      <Card component={Box} p={2}>
        {recordIsLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Box display="flex" mt={2}>
              <Box mr={3} mt={1}>
                {senderId === HOPPER_UUID ? (
                  <img src="/svgs/LogoMobile.svg" alt="Bellhop Logo" width="100%" />
                ) : (
                  <AccountCircle fontSize="large" />
                )}
              </Box>
              <Box flex={1}>
                <Box>
                  <Box mb={2}>
                    <Typography component={Box} mb={1}>
                      {senderId === HOPPER_UUID ? 'Hopper' : senderAccount?.name}
                    </Typography>
                    <Typography component={Box} mb={1}>
                      {title}
                    </Typography>
                    <Typography variant="body2">
                      <em>Created By:</em> {createdBy === HOPPER_UUID ? 'Hopper' : createdByAccount?.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      component={Box}
                      mb={1}
                    >{`Sent to ${accountsText} accounts with filters:`}</Typography>
                    <Chip label={roleDisplay} sx={{ textTransform: 'capitalize' }} component={Box} mr={1} />
                    {marketsData?.map(({ id, name }) => (
                      <Chip key={id} label={name} component={Box} mr={1} />
                    ))}
                    {typeof markets === 'undefined' ? <Chip label="All Markets" /> : null}
                    {typeof jobsGte !== 'undefined' ? <Chip label={`Jobs >= ${jobsGte}`} component={Box} mr={1} /> : null}
                    {typeof jobsLte !== 'undefined' ? <Chip label={`Jobs <= ${jobsLte}`} component={Box} mr={1} /> : null}
                  </Box>
                </Box>
              </Box>
              <Box textAlign="right">
                <Box mb={1}>
                  <Typography>{dayjs(createdDate).format('MM/DD/YYYY h:mm A')}</Typography>
                </Box>
                <Chip label={type === '' ? 'No Type' : type} mt={1} />
              </Box>
            </Box>
            <Box mt={5} px={2} whiteSpace="pre-wrap">
              {message}
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};

const CommunicationsShow = () => (
  <ShowBase>
    <Communication />
  </ShowBase>
);

export default CommunicationsShow;
