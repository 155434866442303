import React, { useEffect } from 'react';
import {
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  Link,
  ListBase,
  ListToolbar,
  LoadingIndicator,
  Pagination,
  required,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  Title,
  useAuthProvider,
  useGetIdentity,
  useGetList,
  useGetOne,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';

import { Box, Button, Link as MuiLink, Typography } from '@mui/material';
import { stringify } from 'query-string';
import dayjs from 'dayjs';
import { Add, Create } from '@mui/icons-material';
import RESOURCE_VERSIONS from '@/api/resourceVersions';
import DatagridEmpty from '@/shared/DatagridEmpty';
import onlyUnique from '@/utils/onlyUnique';
import postData from '@/utils/postData';
import putData from '@/utils/putData';

const ConversationField = () => {
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const { getAccessToken } = useAuthProvider();
  const {
    conversation_id: conversationId,
    order_id: orderId,
    start_market_id: startMarket,
    end_market_id: endMarket,
  } = useRecordContext();
  const { data: orderMarketsData } = useGetList('markets', {
    pagination: { page: 1, perPage: 999 },
    sort: { field: 'name', order: 'ASC' },
    filter: { id: [startMarket, endMarket].filter(onlyUnique) },
  });
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const {
    data: conversationData,
    isLoading: isConversationLoading,
    refetch: refetchConversation,
  } = useGetOne('conversations', { id: conversationId }, { enabled: Boolean(conversationId) });

  const joinConversation = async () => {
    if (!identity || !conversationId) return;
    const resourceVersion = RESOURCE_VERSIONS.conversations;
    const token = await getAccessToken();
    const conversationUrl = `${process.env.REACT_APP_API_URL}/${resourceVersion}/conversations/${conversationId}`;
    const participantUrl = `${conversationUrl}/users`;
    const { criteria } = conversationData;

    putData(
      conversationUrl,
      {
        ...criteria,
        users: [...(criteria?.users ?? []), identity?.id],
      },
      token,
    )
      .then(async (response) => {
        if (!response.ok) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ status: response.status, result: await response.json() });
        }

        return response.json();
      })
      .then(() => {
        postData(participantUrl, { id: identity.id }, token)
          .then(async (response) => {
            if (!response.ok) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject({ status: response.status, result: await response.json() });
            }
            return response.json();
          })
          .then(() => {
            // Initiate refetch so if user comes back from conversation page, the new data exists for accurate UI display
            refetchConversation();
            // Redirect to conversation just joined
            redirect('show', 'conversations', conversationId);
          })
          .catch((error) => {
            const baseError = 'An error occurred joining the conversation';
            if (error.status) {
              notify(`${baseError} - ${error.status} - ${error.result?.message ?? error.result}`);
            } else {
              notify(`${baseError} - Unknown Error - ${error}`);
            }
          });
      })
      .catch((error) => {
        const baseError = 'An error occurred updating the conversation criteria';
        if (error.status) {
          notify(`${baseError} - ${error.status} - ${error.result?.message ?? error.result}`);
        } else {
          notify(`${baseError} - Unknown Error - ${error}`);
        }
      });
  };

  const createConversation = async () => {
    if (!identity || !orderId) return;
    const token = await getAccessToken();
    const url = `${process.env.REACT_APP_API_URL}/v1/orders/${orderId}/conversation`;
    const isMM = orderMarketsData.some((market) => market.manager_id === identity.id);
    const data = {
      ...(!isMM && { users: [identity.id] }),
    };
    postData(url, data, token)
      .then(async (response) => {
        if (!response.ok) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ status: response.status, result: await response.json() });
        }
        return response.json();
      })
      .then(({ id }) => {
        // Initiate refresh so if user comes back from conversation page, new jobs data exists with newly created conversation
        refresh();
        // Redirect to conversation just joined
        redirect('show', 'conversations', id);
      })
      .catch((error) => {
        const baseError = 'An error occurred creating the conversation';
        if (error.status) {
          notify(`${baseError} - ${error.status} - ${error.result?.message ?? error.result}`);
        } else {
          notify(`${baseError} - Unknown Error - ${error}`);
        }
      });
  };

  if (typeof conversationId === 'undefined') return null; // Check undefined first - This will exit when value is unloaded

  if (!conversationId) {
    // No conversation ID on job - Show create if user has permissions - show no button if not
    if (permissions?.resources?.conversations?.create) {
      return (
        <Button variant="outlined" startIcon={<Create />} onClick={createConversation}>
          Create
        </Button>
      );
    }
    return null;
  }

  if (isConversationLoading) {
    return <LoadingIndicator />;
  }

  if (conversationData?.participants.some(({ user_id: userId }) => identity?.id === userId)) {
    // Determine if current user is participant, show view if they are
    return (
      <ShowButton
        resource="conversations"
        record={{ id: conversationId }}
        label="View"
        variant="outlined"
        sx={{ padding: '8px 15px' }}
      />
    );
  }

  // Conversation exists, current user is not participant
  return (
    <Button variant="outlined" startIcon={<Add />} onClick={joinConversation} title={conversationId}>
      Join
    </Button>
  );
};

const JobsFilterForm = () => {
  const { filterValues, setFilters } = useListContext();

  const { data: markets } = useGetList('markets', {
    pagination: { page: 1, perPage: 999 },
    sort: { field: 'name', order: 'ASC' },
  });

  const defaultValues = { status: 'OPEN' };

  const methods = useForm({
    defaultValues: !_.isEmpty(filterValues) ? filterValues : defaultValues,
  });

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { isDirty: formIsDirty },
  } = methods;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      if (values?.order_confirmation_code) {
        setFilters({
          order_confirmation_code: values.order_confirmation_code,
          status: ['OPEN', 'COMPLETE', 'CANCELLED'],
        });
      } else if (values?.date) {
        const startDateLTE = dayjs(values?.date).hour(23).minute(59).second(59).toISOString();
        const endDateGTE = dayjs(values?.date).hour(0).minute(0).second(0).toISOString();
        setFilters({
          ...values,
          date: null,
          start_date_time_lte: startDateLTE,
          end_date_time_gte: endDateGTE,
        });
      } else {
        setFilters({
          ...values,
          date: null,
          start_date_time_lte: null,
          end_date_time_gte: null,
        });
      }
      reset(getValues());
    }
  };

  const resetFilters = () => {
    setFilters(defaultValues, Object.keys(defaultValues));
    reset(defaultValues, { keepValues: false });
  };

  useEffect(() => {
    if (filterValues.start_date_time_lte && filterValues.end_date_time_gte) {
      setValue('date', dayjs(filterValues.start_date_time_lte).toISOString());
    }
  }, [filterValues.start_date_time_lte, filterValues.end_date_time_gte]);

  useEffect(() => {
    if (filterValues.market_id) {
      setValue('market_id', filterValues.market_id);
    } else {
      setValue('market_id', '');
    }
  }, [filterValues.market_id]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box display="flex" px={4} py={2}>
            <Box flex={1} display="flex">
              <Box mr={2}>
                <SelectInput source="market_id" optionText="name" choices={markets ?? []} />
              </Box>
              <Box mr={2}>
                <DateInput source="date" label="Date" />
              </Box>
              <Box mr={2}>
                <SelectInput
                  source="status"
                  choices={[
                    { id: 'OPEN', name: 'Open' },
                    { id: 'COMPLETE', name: 'Complete' },
                    { id: 'CANCELLED', name: 'Cancelled' },
                  ]}
                  validate={required()}
                />
              </Box>
            </Box>

            <Box mr={4} mt={3}>
              OR
            </Box>
            <Box>
              <Box>
                <TextInput source="order_confirmation_code" label="Order #" />
              </Box>
              <Box>
                <Typography>
                  <strong>Note:</strong> If any value is entered for <strong>Order #</strong>, all other filters are ignored
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" my={2} mx={3}>
            <Box>
              <Button variant="contained" onClick={resetFilters} color="neutral">
                Reset
              </Button>
            </Box>
            <Box>
              <Button variant="contained" color="primary" type="submit" disabled={!formIsDirty}>
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

const ListActions = () => (
  <Box width="100%" sx={{ borderBottom: '1px solid #333' }}>
    <JobsFilterForm />
  </Box>
);

const CommsJobs = () => {
  const { identity } = useGetIdentity();
  const { data: userMarkets } = useGetList(
    'markets',
    {
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'name', order: 'ASC' },
      filter: {
        manager_id: identity?.id,
      },
    },
    {
      enabled: Boolean(identity),
    },
  );
  const { data: markets } = useGetList('markets', {
    pagination: { page: 1, perPage: 999 },
    sort: { field: 'name', order: 'ASC' },
  });

  const today = dayjs().tz('America/New_York');
  const todayStartOfDay = today.hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss');
  const todayEndOfDay = today.hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss');
  const intlDateOptions = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  const filterDefaultValues = {
    status: 'OPEN',
  };

  const quickLinkMarkets = userMarkets?.length === 0 ? markets : userMarkets;

  return (
    <Box display="flex" height="calc(100vh - 48px)" width="100%">
      <Box
        width={300}
        flexShrink={0}
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: (theme) => theme.palette.secondary.darker,
          color: (theme) => theme.palette.neutral.lightest,
          borderTop: (theme) => `2px solid ${theme.palette.neutral.dark}`,
          borderRight: (theme) => `2px solid ${theme.palette.neutral.dark}`,
          overflowY: 'auto',
        }}
      >
        <Box m={2}>
          <Box>
            <Typography variant="h6">In-flight Jobs Today</Typography>
          </Box>
          {quickLinkMarkets?.map(({ id, name }) => (
            <Box key={id}>
              <Box py={1} px={4}>
                <Link
                  to={`/comms/jobs?${stringify({
                    displayedFilters: JSON.stringify({
                      start_date_time_lte: true,
                      end_date_time_gte: true,
                      market_id: true,
                      status: true,
                    }),
                    // Links pre set filter to jobs starting before end of day today, and ending after beginning of day today
                    // This will pickup local move jobs that start and end today, as well as longer multi day LD transit jobs currently in progress
                    filter: JSON.stringify({
                      start_date_time_lte: todayEndOfDay,
                      end_date_time_gte: todayStartOfDay,
                      market_id: id,
                      status: 'OPEN',
                    }),
                    page: 1,
                    perPage: 25,
                  })}`}
                >
                  <Typography variant="body2">{name}</Typography>
                </Link>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box width="100%" height="100%" display="flex" flexDirection="column">
        <ListBase
          resource="jobs"
          perPage={25}
          page={1}
          sort={{ field: 'start_date_time', order: 'ASC' }}
          filterDefaultValues={filterDefaultValues}
        >
          <Title title="Jobs" />
          <Box sx={{ flexShrink: 0 }}>
            <ListToolbar actions={<ListActions />} sx={{ flexShrink: 0 }} />
          </Box>
          <Box flex={1} position="relative" sx={{ overflowY: 'auto' }}>
            <Datagrid bulkActionButtons={false} empty={<DatagridEmpty />}>
              <FunctionField
                label="Order #"
                render={(record) =>
                  record.order_sfid ? (
                    <MuiLink
                      target="_blank"
                      href={`${process.env.REACT_APP_SALESFORCE_URI}/lightning/r/Order/${record.order_sfid}/view`}
                      sx={{ textDecoration: 'none' }}
                    >
                      {record.order_confirmation_code}
                    </MuiLink>
                  ) : (
                    <TextField source="order_confirmation_code" />
                  )
                }
              />
              <DateField source="start_date_time" label="Job Start" showTime options={intlDateOptions} />
              <DateField source="end_date_time" label="Job End" showTime options={intlDateOptions} />
              <TextField source="start_market_id" />
              <TextField source="end_market_id" />
              <TextField source="product_id" />
              <TextField source="status" />
              <ConversationField label="Conversation" />
            </Datagrid>
          </Box>
          <Box sx={{ borderTop: '1px solid #333' }}>
            <Pagination limit={null} />
          </Box>
        </ListBase>
      </Box>
    </Box>
  );
};

export default CommsJobs;
