import React from 'react';
import {
  Datagrid,
  DateField,
  DateTimeInput,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

const JobsList = () => {
  const filters = [
    <ReferenceInput source="market_id" reference="markets" perPage={999} sort={{ field: 'name', order: 'ASC' }}>
      <SelectInput optionText="name" />
    </ReferenceInput>,
    <DateTimeInput source="start_date_time_lte" label="Starts Before" />,
    <DateTimeInput source="start_date_time_gte" label="Starts After" />,
    <DateTimeInput source="end_date_time_lte" label="Ends Before" />,
    <DateTimeInput source="end_date_time_gte" label="Ends After" />,
    <TextInput source="order_confirmation_code" label="Order #" />,
    <SelectInput
      source="status"
      optionText="name"
      choices={[
        { id: 'OPEN', name: 'Open' },
        { id: 'COMPLETE', name: 'Complete' },
        { id: 'CANCELLED', name: 'Cancelled' },
        { id: 'REMOVED', name: 'Removed' },
      ]}
    />,
  ];

  const intlDateOptions = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  return (
    <List filters={filters}>
      <Datagrid bulkActionButtons={null} rowClick="show">
        <TextField source="id" />
        <TextField source="order_confirmation_code" />
        <DateField source="start_date_time" label="Job Start" showTime options={intlDateOptions} />
        <DateField source="end_date_time" label="Job End" showTime options={intlDateOptions} />
        <TextField source="start_market_id" />
        <TextField source="end_market_id" />
        <TextField source="product_id" />
        <TextField source="status" />
        <FunctionField label="Conversation" render={({ conversation_id: conversationId }) => (conversationId ? 'Y' : 'N')} />
      </Datagrid>
    </List>
  );
};

export default JobsList;
