import { useEffect, useState } from 'react';
import { useGetIdentity, useGetList, usePermissions } from 'react-admin';

// Hook dependent on React Admin identity - If no identity then hook will always return null
const useDirectMessageConversations = () => {
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();

  const [mappedDirectConversations, setMappedDirectConversations] = useState([]);

  const { data: directConversations, refetch: refetchDirectConversations } = useGetList(
    'conversations',
    {
      pagination: { page: 1, perPage: 999 },
      filter: {
        user_id: identity?.id,
        name: 'Market Manager DM',
      },
    },
    {
      enabled:
        Boolean(permissions?.resources?.accounts?.read) &&
        Boolean(permissions?.resources?.conversations?.read) &&
        Boolean(identity),
    },
  );

  useEffect(() => {
    if (directConversations) {
      const newMappedConversations = directConversations.map((c) => {
        const recipientId = c.participants.find((p) => p.user.id !== identity?.id)?.user.id;
        const recipientName = c.metadata[recipientId];
        return {
          ...c,
          recipientName,
        };
      });

      setMappedDirectConversations(newMappedConversations);
    }
  }, [directConversations]);

  if (!identity) {
    // If no identity found - we don't know who user is to determine direct message conversations
    return { data: null };
  }

  return { data: mappedDirectConversations, refetch: refetchDirectConversations };
};

export default useDirectMessageConversations;
