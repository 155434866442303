import React, { memo } from 'react';

import { Box, Divider } from '@mui/material';

const UnreadMarker = () => (
  <Box
    display="flex"
    sx={{
      color: 'red',
    }}
  >
    <Box width="100%" mx={5}>
      <Divider color="red" />
    </Box>
    <Box position="relative" left="calc(-50% - 16px)" top={-10}>
      <Box
        position="absolute"
        px={2}
        sx={{
          backgroundColor: 'white',
          width: 'auto',
        }}
      >
        Unread
      </Box>
    </Box>
  </Box>
);

export default memo(UnreadMarker);
