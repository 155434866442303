import React from 'react';
import { Link, useCreatePath, useGetList } from 'react-admin';

import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';

const RecentComms = () => {
  const { data: communications, communicationsIsLoading } = useGetList('communications', {
    sort: { field: 'created_date', order: 'DESC' },
    pagination: { page: 1, perPage: 99 },
    filter: {
      created_date_gte: dayjs().subtract(14, 'day').format('YYYY-MM-DD'),
      type: ['Market Update', 'Launch', 'New Feature', 'Outage Update', 'Announcement'],
    },
  });
  const createPath = useCreatePath();

  return (
    <Box>
      {communications && !communicationsIsLoading ? (
        <Box>
          {communications.map(({ id, title, created_date: createdDate, user_ids: userIds }) => (
            <Link key={id} to={createPath({ resource: 'communications', type: 'show', id })}>
              <Box
                py={1}
                px={3}
                sx={{
                  color: ({ palette }) => palette.text.primary,
                  '&:hover': {
                    backgroundColor: ({ palette }) => palette.neutral.light,
                  },
                }}
              >
                <Box>
                  <Typography>{title}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2" color="neutral.medium">
                    {dayjs(createdDate).format('MM-DD-YYYY h:mm A')}
                  </Typography>
                  <Typography variant="body2" color="neutral.medium">
                    {userIds?.length} Recipients
                  </Typography>
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      ) : (
        <>Loading</>
      )}
    </Box>
  );
};

export default RecentComms;
