import React, { useEffect, useState } from 'react';
import { usePermissions } from 'react-admin';
import { useSearchParams } from 'react-router-dom';

import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

import ConversationShow from '@/resources/conversations/Show';
import TwilioClientProvider from '@/providers/TwilioClient';
import CreateDmLookup from '@/shared/CreateDmLookup';

import HubConversations from './HubConversations';

const CommsHub = () => {
  const { permissions } = usePermissions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedConversation, setSelectedConversation] = useState(null);

  useEffect(() => {
    const presetConversation = searchParams.get('selectedConversation');
    if (presetConversation) {
      setSelectedConversation(presetConversation);
      setSearchParams({}, { replace: true });
    }
  }, [searchParams]);

  return (
    <Box display="flex" height="calc(100vh - 48px)" width="100%">
      <TwilioClientProvider>
        <HubConversations selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation} />

        <Box width="100%" height="100%" display="flex" flexDirection="column">
          {selectedConversation ? (
            <ConversationShow conversationId={selectedConversation} />
          ) : (
            <Box p={3}>
              <Card component={Box} maxWidth={400} mx="auto" mb={5}>
                <CardContent>
                  <Typography variant="h6" textAlign="center">
                    Select a conversation from the left to view
                  </Typography>
                </CardContent>
              </Card>
              {permissions?.apps?.commsTool?.createDm ? (
                <Card component={Box} maxWidth={400} mx="auto">
                  <CardHeader title="Create DM" />
                  <Divider />
                  <CardContent>
                    <CreateDmLookup />
                  </CardContent>
                </Card>
              ) : null}
            </Box>
          )}
        </Box>
      </TwilioClientProvider>
    </Box>
  );
};

export default CommsHub;
