import palette from '@/theme/palette';
import mixins from '@/theme/mixins';

export default {
  styleOverrides: {
    containedPrimary: {
      color: palette.neutral.lightest,
      background: mixins.gradient(palette.primary.light, palette.primary.main),
      '&:hover': {
        background: mixins.gradient(
          // This is gradient because it flashes white switching from gradient to non gradient
          palette.primary.dark,
          palette.primary.dark,
        ),
      },
    },
  },
};
