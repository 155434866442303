import React from 'react';
import { func, node, oneOfType, string } from 'prop-types';
import { Sidebar, useSidebarState } from 'react-admin';

import { Box } from '@mui/material';

import MyAppBar from '../AppBar';
import Menu from '../Menu';

const Root = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    sx={{
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: ({ palette }) => palette.background.default,
      position: 'relative',
    }}
  >
    {children}
  </Box>
);

Root.propTypes = {
  children: oneOfType([func, node]),
};

Root.defaultProps = {
  children: null,
};

const AppFrame = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    mt={6}
    sx={{
      overflowX: 'auto',
    }}
  >
    {children}
  </Box>
);

AppFrame.propTypes = {
  children: oneOfType([func, node]),
};

AppFrame.defaultProps = {
  children: null,
};

const ContentWithSidebar = ({ children }) => (
  <Box component="main" display="flex" flexGrow={1}>
    {children}
  </Box>
);

ContentWithSidebar.propTypes = {
  children: oneOfType([func, node]),
};

ContentWithSidebar.defaultProps = {
  children: null,
};

const Content = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    flexGrow={2}
    maxWidth="calc(100% - 60px)"
    maxHeight="calc(100vh - 48px)"
    overflow="auto"
  >
    {children}
  </Box>
);

Content.propTypes = {
  children: oneOfType([func, node]),
};

Content.defaultProps = {
  children: null,
};

const MyLayout = ({ title, dashboard, children }) => {
  const [open] = useSidebarState();

  return (
    <Root>
      <AppFrame>
        <MyAppBar title={title} open={open} />
        <ContentWithSidebar>
          <Sidebar>
            <Menu hasDashboard={!!dashboard} />
          </Sidebar>
          <Content>{children}</Content>
        </ContentWithSidebar>
      </AppFrame>
    </Root>
  );
};

MyLayout.propTypes = {
  title: string.isRequired,
  dashboard: oneOfType([func, string]),
  children: oneOfType([func, node]),
};

MyLayout.defaultProps = {
  dashboard: null,
  children: null,
};

export default MyLayout;
