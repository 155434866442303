import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shape } from 'prop-types';
import { useGetIdentity } from 'react-admin';

import { Box, Typography } from '@mui/material';

// import callbackBeforeUnmount from '@/utils/callbackBeforeUnmount';
import ConversationMessage from '../ConversationMessage';

const useOnScreen = (ref, triggers = []) => {
  const observerRef = useRef(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  const observerCallback = ([entry]) => {
    setIsOnScreen(entry.isIntersecting);
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(observerCallback, { threshold: 0.95 });
  }, []);

  useEffect(() => {
    if (!!observerRef.current && !!ref.current) {
      observerRef.current.observe(ref.current);
    }

    return () => {
      observerRef?.current?.disconnect();
    };
  }, [ref, ...triggers]);

  const data = useMemo(() => isOnScreen, [isOnScreen]);

  return data;
};

// const useMultipleOnScreen = (ref, triggers) => {
//   const observerRef = useRef(null);
//   const [isOnScreen, setIsOnScreen] = useState({});

//   const observerCallback = (entries) => {
//     entries.forEach((entry) => {
//       setIsOnScreen((prev) => ({ ...prev, [entry.target.dataset.messageIndex]: entry.isIntersecting }));
//     });
//   };

//   useEffect(() => {
//     observerRef.current = new IntersectionObserver(observerCallback, { threshold: 0.95 }); // Threshold of 95% so only triggers when whole element is visible
//   }, []);

//   useEffect(() => {
//     if (!!observerRef.current && !!ref.current && ref.current.length > 0) {
//       observerRef?.current?.disconnect();
//       ref.current.forEach((item) => observerRef.current.observe(item));
//     }

//     return () => {
//       observerRef?.current?.disconnect();
//     };
//   }, [ref, ...triggers]);

//   const data = useMemo(() => isOnScreen, [isOnScreen]);

//   return data;
// };

const ConversationMessages = ({ conversation }) => {
  const { identity } = useGetIdentity();
  const [messages, setMessages] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [lastReadMessage, setLastReadMessage] = useState(0);
  const [hasPrevPage, setHasPrevPage] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  // Window analogy for how prev/next page promises function
  const [prevPage, setPrevPage] = useState();
  const [nextPage, setNextPage] = useState();
  const scrollContainer = useRef(null);
  const lastReadRef = useRef(null);
  const messagesRef = useRef([]);
  const testRef = useRef(null);
  const prevPageTriggerRef = useRef(null);
  const nextPageTriggerRef = useRef(null);
  // const isMultipleOnScreen = useMultipleOnScreen(messagesRef, [messages]);
  // const [oldestMessageViewed, setOldestMessageViewed] = useState(Number.MAX_SAFE_INTEGER);
  // const [newestMessageViewed, setNewestMessageViewed] = useState(-1);
  const [uiReady, setUiReady] = useState(false);
  const [prevPageTrigger, setPrevPageTrigger] = useState();
  const [nextPageTrigger, setNextPageTrigger] = useState();
  const isPrevPageTriggerOnScreen = useOnScreen(prevPageTriggerRef, [messages]);
  const isNextPageTriggerOnScreen = useOnScreen(nextPageTriggerRef, [messages]);
  const [clientAtBottom, setClientAtBottom] = useState(false);

  const updateReadHorizon = (index) => {
    conversation.advanceLastReadMessageIndex(index);
  };

  // This auto advances read horizon when unmounting to advance to newest message in view
  // Commented out until other unread functionality is proofed
  // callbackBeforeUnmount(updateReadHorizon, newestMessageViewed);

  const scrollToBottom = () => {
    const newMessageRef = messagesRef.current[messagesRef.current.length - 1];
    if (newMessageRef) {
      newMessageRef.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (uiReady && clientAtBottom && messagesRef?.current) {
      scrollToBottom();
    }
  }, [newMessages, clientAtBottom, uiReady]);

  const handleLoadMessages = (messagePaginator) => {
    setHasPrevPage(messagePaginator.hasPrevPage);
    setHasNextPage(messagePaginator.hasNextPage);
    // Trigger indices used to load prev/next pages, set to trigger once within 5 messages of either end
    if (messagePaginator.hasPrevPage) {
      setPrevPageTrigger(messagePaginator.items?.[4]?.index);
      setPrevPage(messagePaginator.prevPage());
    }
    if (messagePaginator.hasNextPage) {
      setNextPageTrigger(messagePaginator.items[messagePaginator.items.length - 5].index);
      setNextPage(messagePaginator.nextPage());
    }
    setMessages(messagePaginator.items);
  };

  const initConversationMessages = () => {
    conversation.getUnreadMessagesCount().then((unreadCount) => {
      if (unreadCount === null) {
        // If unread is null - no messages read ever - load 30 from start - uses undefined as anchor instead of zero to prevent false positive previous page flag
        conversation.getMessages(30, undefined, 'forward').then(handleLoadMessages);
      } else if (unreadCount >= 25) {
        // If unread is greater than 25 - load last five read messages and 25 new ones
        // Edge case if unread greater than 25 and less than 30 messages, anchor would be negative, so check anchor greater than 0, if not, use undefined as anchor to prevent false positive previous page flag
        const messageAnchor = conversation.lastReadMessageIndex - 5;
        conversation.getMessages(30, messageAnchor > 0 ? messageAnchor : undefined, 'forward').then(handleLoadMessages);
      } else {
        // otherwise - no unread or less than 25 - load latest 30
        conversation.getMessages().then(handleLoadMessages);
      }
      console.log('Twilio conversation - Retrieved messages');
    });
  };

  const addMessage = (newMessage) => {
    if (newMessage.author === identity?.id) {
      conversation.advanceLastReadMessageIndex(newMessage.index).then(() => {
        if (hasNextPage) {
          setUiReady(false);
          initConversationMessages();
        }
      });
    }
    setNewMessages((prev) => [...prev, newMessage]);
  };

  const refreshConversation = () => {
    setMessages([]);
    setNewMessages([]);
    initConversationMessages();
  };

  const updateLastReadMessage = () => {
    if (conversation.lastReadMessageIndex === null) {
      setLastReadMessage(-1);
    } else {
      setLastReadMessage(conversation.lastReadMessageIndex);
    }
  };

  const loadPrevPage = async () => {
    const newMessagePaginator = await prevPage;

    setHasPrevPage(newMessagePaginator.hasPrevPage);
    if (newMessagePaginator.hasPrevPage) {
      setPrevPageTrigger(newMessagePaginator.items?.[4]?.index);
      setPrevPage(newMessagePaginator.prevPage());
    }
    setMessages((prev) => [...new Set([...newMessagePaginator.items, ...prev])]);
  };

  const loadNextPage = async () => {
    const newMessagePaginator = await nextPage;
    setHasNextPage(newMessagePaginator.hasNextPage);
    if (newMessagePaginator.hasNextPage) {
      setNextPageTrigger(newMessagePaginator.items[newMessagePaginator.items.length - 5].index);
      setNextPage(newMessagePaginator.nextPage());
    }
    setMessages((prev) => [...prev, ...new Set([...newMessagePaginator.items])]);
  };

  useEffect(() => {
    if (hasPrevPage && uiReady && isPrevPageTriggerOnScreen) {
      loadPrevPage();
    }
  }, [hasPrevPage, uiReady, isPrevPageTriggerOnScreen]);

  useEffect(() => {
    if (hasNextPage && uiReady && isNextPageTriggerOnScreen) {
      loadNextPage();
    }
  }, [hasNextPage, uiReady, isNextPageTriggerOnScreen]);

  useEffect(() => {
    if (conversation) {
      updateLastReadMessage();
      initConversationMessages();
      conversation.on('updated', updateLastReadMessage);
    }
    return () => {
      conversation?.removeListener('messageAdded', addMessage);
      conversation?.removeListener('updated', updateLastReadMessage);
    };
  }, [conversation]);

  useEffect(() => {
    // addMessage is dependent on hasNextPage state
    conversation.on('messageAdded', addMessage);
    return () => {
      conversation?.removeListener('messageAdded', addMessage);
    };
  }, [hasNextPage]);

  const scrollToLastRead = () => {
    const { scrollHeight, clientHeight } = scrollContainer.current;
    if (scrollHeight > clientHeight) {
      // message container is scrollable - move last read message to top
      lastReadRef?.current?.scrollIntoView({ behavior: 'smooth' });
      let scrollTimeout;
      const scrollInterval = setInterval(() => {
        const { scrollTop } = scrollContainer.current;
        const { offsetTop } = lastReadRef.current;
        if (scrollTop === offsetTop || scrollHeight - scrollTop === clientHeight) {
          // Checks if scroll has reached intended position or if scroll is at bottom
          setUiReady(true);
          clearInterval(scrollInterval);
          clearInterval(scrollTimeout);
        }
      }, 50);
      scrollTimeout = setTimeout(() => {
        console.error('timing out scroll - edge case infinite interval prevention');
        clearInterval(scrollInterval);
      }, 1500);
    }
  };

  // useEffect(() => {
  //   const visibleMessageIndicies = Object.keys(isMultipleOnScreen).filter((key) => isMultipleOnScreen[key]);

  //   const oldestViewedMessageIndex = Math.min(...visibleMessageIndicies);
  //   const newestViewedMessageIndex = Math.max(...visibleMessageIndicies);
  //   if (uiReady) {
  //     // After initial load, start watching for visible messages to track oldest and newest
  //     if (oldestViewedMessageIndex < oldestMessageViewed) {
  //       setOldestMessageViewed(oldestViewedMessageIndex);
  //     }
  //     if (newestViewedMessageIndex > newestMessageViewed) {
  //       setNewestMessageViewed(newestViewedMessageIndex);
  //     }

  //     // Also trigger prev/next page loads
  //     if (hasPrevPage && isMultipleOnScreen[prevPageTrigger]) {
  //       loadPrevPage();
  //       console.log('load prev page');
  //     }

  //     if (hasNextPage && isMultipleOnScreen[nextPageTrigger]) {
  //       console.log('load next page');
  //     }
  //   } else {
  //     // Goal is to fire timeout to indicate UI has completed loading and scrolled to last read message, if we receive another scroll event within 500 ms, restart timeout
  //     clearTimeout(uiReadyTimeout);
  //     const newTimeout = setTimeout(() => {
  //       console.log('new timeout');
  //       setUiReady(true);
  //     }, 500);
  //     setUiReadyTimeout(newTimeout);
  //   }
  // }, [isMultipleOnScreen]);

  useEffect(() => {
    const update = async () => {
      const unread = await conversation.getUnreadMessagesCount();
      const { scrollHeight, clientHeight } = scrollContainer.current;
      const containerScroll = scrollHeight > clientHeight;
      if (messages?.length > 0 && !uiReady) {
        if (unread !== null && containerScroll) {
          scrollToLastRead();
        } else {
          setUiReady(true);
        }
        // Set initial state of scroll flag - sets to true if no scroll exists
        setClientAtBottom(!containerScroll);
      }
    };

    update();
  }, [messages]);

  const messagesContent = () => {
    let unreadMarkerPlaced = false;
    // If all pages have loaded to end - add new messages after (these are messages that were sent after mount)
    const displayedMessages = hasNextPage ? messages : [...new Set([...messages, ...newMessages])];
    messagesRef.current = []; // Reset ref - fixes hot reload issue where new messages are now in messages
    return displayedMessages?.map((message, index) => {
      const unreadProps = {};
      if (message.index > lastReadMessage && !unreadMarkerPlaced) {
        unreadMarkerPlaced = true;
        unreadProps.addUnreadMarker = true;
      }
      return (
        <Box
          key={message.index}
          data-message-index={message.index}
          ref={(el) => {
            messagesRef.current[index] = el;
            if (hasPrevPage && message.index === prevPageTrigger) {
              prevPageTriggerRef.current = el;
            }
            if (hasNextPage && message.index === nextPageTrigger) {
              nextPageTriggerRef.current = el;
            }
            if (message.index === lastReadMessage || (lastReadMessage === -1 && message.index === 0)) {
              // Set last read ref on last read or on first if none read
              lastReadRef.current = el;
            }
            if (index === messages.length - 1) {
              testRef.current = el;
            }
          }}
        >
          <ConversationMessage
            message={message}
            setLastReadMessage={message.index > lastReadMessage ? updateReadHorizon : () => {}}
            refreshConversation={refreshConversation}
            {...unreadProps}
          />
        </Box>
      );
    });
  };

  const handleScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = scrollContainer.current;
    if (scrollHeight - scrollTop === clientHeight) {
      setClientAtBottom(true);
    } else {
      setClientAtBottom(false);
    }
  };

  return messages ? (
    <>
      <Box
        ref={scrollContainer}
        display="flex"
        flexDirection="column"
        position="absolute"
        sx={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          overflowY: 'auto',
        }}
        onScroll={handleScroll}
      >
        {((!hasPrevPage && uiReady) || messages?.length === 0) && (
          <Typography align="center" py={2} color="gray" fontSize="small">
            This is the beginning of your conversation
          </Typography>
        )}
        <Box>{messagesContent()}</Box>
      </Box>
      {/* <Box>
          Has prev: {hasPrevPage ? 'yes' : 'no'}
        </Box>
        <Box>
          Has next: {hasNextPage ? 'yes' : 'no'}
        </Box> */}
    </>
  ) : null;
};

ConversationMessages.propTypes = {
  conversation: shape({}).isRequired,
};

export default ConversationMessages;
