import palette from '@/theme/palette';

export default {
  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        color: palette.neutral.lightest,
      },
      '& .MuiMenuItem-root': {
        color: palette.neutral.lightest,
      },
      '& .RaMenuItemLink-active': {
        color: palette.neutral.lightest,
      },
      '& .MuiMenuItem-root:hover:not(.RaMenuItemLink-active)': {
        backgroundColor: palette.secondary.dark,
      },
      '& .MuiListItemIcon-root': {
        color: palette.neutral.lightest,
      },
    },
  },
};
